/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-spread */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  useCallback
} from 'react';

import 'react-multi-carousel/lib/styles.css';
import {
  Snackbar,
  Typography,
  useMediaQuery,
  FormControl,
  LinearProgress,
  Tooltip,
  Popover,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { isNan, isNumber } from 'shineout/lib/utils/is';

import SelectButton from 'components/BloomForms/SelectButton';
import Button from 'components/Button/button';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import Discard from 'components/Dialogs/Discard';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import SimpleModal from 'components/SimpleModal/SimpleModal';
import Layout from 'containers/layouts/layout';
import configPackageCount from 'images/icons/objects/boxesAndPackages/configPackageCount.svg';
import HasNotImage from 'images/icons/objects/cams/camIcon.svg';
import infoRedIcon from 'images/icons/signals/alerts/infoRedIcon.svg';
import InfoQuestionIcon from 'images/icons/signals/questionMarks/infoQuestionIcon.svg';
import DialogCount from 'pages/inventory/Counts/dialogs/DialogCount';
import CountGroupCarousel from 'pages/inventory/diary-count/count/CountGroupCarousel';
import CountHeader from 'pages/inventory/diary-count/count/CountHeader';
import {
  addCountedProducts,
  assembleShowProducts,
  focusOnNextField,
  handleFocus,
  handleSelectedRealDiff,
  mapShowProductDescription,
  removeCountedProducts,
  setProviders,
  setSkus,
  unifiedSkus,
  updateProducts,
  formatCountValues
} from 'pages/inventory/diary-count/count/MainFunctions';
import ReportTableBody from 'pages/inventory/diary-count/count/ReportTableBody';
import {
  DivButtomExit,
  GridFooter,
  Table,
  TdCount,
  TdEmb,
  GroupHeaderContainer,
  GroupHeader,
} from 'pages/inventory/diary-count/count/styles';
import DiaryCountTableHead from 'pages/inventory/diary-count/count/TableHead';
import CountDivergentCheckDialog from 'pages/inventory/diary-count/dialogs/CountDivergentCheckDialog/index';
import CountNetworkDialog from 'pages/inventory/diary-count/dialogs/CountNetworkDialog/index';
import DiscardPercentageAndValue from 'pages/inventory/diary-count/dialogs/DiscardPercentageAndValue';
import StockDialog from 'pages/inventory/diary-count/dialogs/StockDialog';
import * as AssortmentActions from 'store/actions/assortment';
import * as UserActions from 'store/actions/user';
import {
  close,
  define,
  defineTitle,
  open
} from 'store/reducers/simpleModalSlice';
import { handleCountColumns } from 'store/reducers/userConfigs';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import { checkQueue, saveSend } from 'utils/misc/queue';
import ValidationLength from 'utils/utils';

import ProductListItem from './ProductListItem/index';

const environments = Environment(process.env.REACT_APP_ENV);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);
const ConfigPackageDialog = React.lazy(() =>
  import('pages/inventory/diary-count/dialogs/ConfigPackageCount/index')
);
const CountDivergentDialog = React.lazy(() =>
  import('pages/inventory/diary-count/dialogs/CountDivergentDialog/index')
);

function DiaryCount({ userInfo, countInfo }) {
  const inputRef = useRef([]);
  const history = useHistory();
  const queryParams = useLocation()?.search;
  const smallScreen = useMediaQuery('(max-width: 1366px)');
  const url = window.location.href;
  const params = new URLSearchParams(url);

  const descriptionName = params.get('descriptionName');

  const { id: paramsID, countId: urlParamCountId } = useParams();
  const productFilter = {
    id: queryParams?.includes('productFilterId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('productFilterId'))
          ?.split('=')[1]
      : '',
    description: queryParams?.includes('productFilterDescription')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('productFilterDescription'))
            ?.split('=')[1]
        )
      : ''
  };

  const currentCountInfo = countInfo || {
    id: queryParams?.includes('countId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('countId'))
          ?.split('=')[1]
      : '',
    date: queryParams?.includes('countDate')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('countDate'))
            ?.split('=')[1]
        )
      : '',
    origin: queryParams?.includes('countOrigin')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('countOrigin'))
          ?.split('=')[1]
      : '',
    assortmentInfo: {
      date: queryParams?.includes('countAssortDate')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('countAssortDate'))
            ?.split('=')[1]
        : '',
      user: queryParams?.includes('countAssortUser')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('countAssortUser'))
            ?.split('=')[1]
        : ''
    }
  };

  const date = currentCountInfo?.date || null;
  const [periodSubtractDays, setPeriodSubtractDays] = useState(
    countInfo?.subtractDays || 0
  );

  const [getId, setGetId] = useState(paramsID);
  const [products, setProducts] = useState([]);
  const [countDate, setCountDate] = useState(currentCountInfo?.date);
  const [currentInput, setCurrentInput] = useState('');
  const [updateValueByModal, setUpdateValueByModal] = useState(false);
  const [disabledConfirmCount, setDisabledConfirmCount] = useState(false);
  const [disableReportFirstReq, setDisableReportFirstReq] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const storedUserCountConfig = localStorage.getItem('countConfigs')?.length
    ? localStorage.getItem('countConfigs')?.split(',')
    : [];
  const [showColumns, setShowColumns] = useState(storedUserCountConfig || []);
  const popoverOpen = Boolean(anchorEl);
  const [periodicity, setPeriodicity] = useState(
    history.location.state?.countGroup?.periodicity
  );
  const [filterSelectedProduct, setFilterSelectedProduct] = useState(
    descriptionName || productFilter || ''
  );
  const [isLoadingAssortment, setIsLoadingAssortment] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [updateTotalDiffValues, setUpdateTotalDiffValues] = useState(1);
  const [storageLocationDescription, setStorageLocationDescription] =
    useState();
  const [setupId, setSetupId] = useState(
    currentCountInfo.id && currentCountInfo.origin === 'INVENTORIES'
      ? currentCountInfo.id
      : urlParamCountId?.split('?')[0]
  );
  const [confirmModalAction, setConfirmModalAction] = useState('');
  const [countIdArray, setCountIdArray] = useState(
    history.location.state?.currentActiveTab
  );
  const [countId, setCountId] = useState(
    currentCountInfo.id && currentCountInfo.origin === 'COUNTS'
      ? Array.isArray(history.location.state?.currentActiveTab)
        ? ''
        : currentCountInfo.id
      : urlParamCountId?.split('?')[0]
  );
  const reportTabObj = {
    active: Array.isArray(history.location.state?.currentActiveTab),
    setup: {
      storageLocation: {
        description: 'Relatório'
      }
    },
    type: 'report'
  };
  const [countGroupInfos, setCountGroupInfos] = useState({
    isCountGroup: !(
      history.location.state?.isCountGroup === 'false' ||
      history.location?.state?.isCountGroup === false ||
      history.location.state?.isCountGroup?.state === false ||
      !history.location.state?.isCountGroup
    ),
    countGroup: {
      ...history.location.state?.countGroup,
      counts: [
        history.location.state?.countGroup?.counts.map((count, countIndex) => {
          if (
            history.location.state?.currentActiveTab ===
            count?.setup?.storageLocation?.id
          ) {
            return {
              ...count,
              active: true
            };
          }
          return {
            ...count,
            active: false
          };
        }),
        reportTabObj
      ].flat()
    }
  });

  const countConvertId = urlParamCountId?.split('?')[0]

  const [status, setStatus] = useState();
  const [hasProcessingInProgress, setHasProcessingInProgress] = useState();
  const [radioValue, setRadioValue] = useState('ALPHABETICAL');
  const [countCategoryDescription, setCountCategoryDescription] = useState('');
  const [assortmentsOptions, setAssortmentsOptions] = useState([]);
  const [assortmentDate, setAssortmentDate] = useState(
    currentCountInfo.assortmentInfo?.date &&
      currentCountInfo?.assortmentInfo?.date !== 'null'
      ? currentCountInfo.assortmentInfo
      : null
  );
  const [toastOpen, setToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState({});
  const [
    differenceBetweenCountAndMenuAlert,
    setDifferenceBetweenCountAndMenuAlert
  ] = useState(null);
  const [secondParamsIdChange, setSecondParamsIdChange] = useState(false);
  const [lastExtractSale, setLastExtractSale] = useState();
  const [parametersInfos, setParametersInfos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingReportInfos, setLoadingReportInfos] = useState(false);
  const [productDialogList, setProductDialogList] = useState([]);
  const [productDialogTitle, setProductDialogTitle] = useState({});
  const [inBase, setInBase] = useState(true);
  const [filterOnlyNotCounted, setFilterOnlyNotCounted] = useState(false);
  const [orderByQuantityDesc, setOrderByQuantityDesc] = useState(false);
  const [isSelectedQuantity, setIsSelectedQuantity] = useState(true);
  const [isSelectedCost, setIsSelectedCost] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);
  const [isSelectedRealDiff, setIsSelectedRealDiff] = useState(false);
  const [enabledFilterByDiff, setEnabledFilterByDiff] = useState([
    'positive',
    'expected',
    'negative'
  ]);
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [checkConsume, setCheckConsume] = useState(false);
  const [countedProducts, setCountedProducts] = useState([]);
  const [hasCountedOthersStocks, setHasCountedOthersStocks] = useState(false);
  const [parameters, setParameters] = useState('');
  const [loadingDiscard, setLoadingDiscard] = useState(false);
  const [salesInfos, setSalesInfos] = useState([]);
  const [diversion, setDiversion] = useState(0);
  const [productModal, setProductModal] = useState({});
  const [productDescription, setProductDescription] = useState('-');
  const [configPackageSkuItems, setConfigPackageSkuItems] = useState([]);
  const [currentCountDivergentDialogData, setCurrentCountDivergentDialogData] =
    useState();
  const [lastProductCounted, setLastProductCounted] = useState(null);
  const [currentDiscardReason, setCurrentDiscardReason] = useState('');
  const [modalToNotReloadList, setModalToNotReloadList] = useState([
    'modalConfigPackage'
  ]);
  const reportTabInfos = countGroupInfos?.countGroup?.counts?.find(
    (el) => el?.type === 'report'
  );

  const [modalSettings, setModalSettings] = useState([
    {
      id: 2,
      name: 'extracts',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      data: [],
      descriptionSku: null
    },
    { id: 1, name: 'stockModal', open: false, fullScreen: false }
  ]);
  const [modalStates, setModalStates] = useState({
    countDivergentCheckDialog: false,
    productDialog: false,
    modalCards: false,
    modalDiscard: false,
    modalProduct: false,
    modalEmpty: false,
    modalConfigPackage: false,
    countDivergentDialog: false,
    confirmModal: false,
    confirmCount: false,
    hasCatalogChanges: countGroupInfos?.countGroup?.counts
      ?.map(
        (count) =>
          count?.id === parseFloat(countId) && count
      )
      .flat()
      .filter((item) => !!item)[0]?.hasChange === 'true',
    countNetworkDialog: false,
    discardPercentageAndValue: false,
    stockModal: false
  });

  const [realStockModal, setRealStockModal] = useState([])
  const [modalConfirmCountObj, setModalConfirmCountObj] = useState();
  const [confirmModalInfos, setConfirmModalInfos] = useState({
    name: '',
    contentText: ''
  });
  const [newData, setNewData] = useState({
    card: null,
    data: {
      description: null,
      originId: getId,
      unitsMeasurementsId: null,
      categoryId: null,
      cardsId: null,
      period: null,
      startDateCurrent: null,
      endDateCurrent: null,
      user: userInfo.user,
      pdv: {
        integrated: false,
        code: null,
        description: null
      }
    },
    discard: []
  });
  const [infoDiscard, setInfoDiscard] = useState();
  const [valuesDiscard, setValuesDiscard] = useState({
    products: 0,
    totalValue: 0
  });
  
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const backFunction = () => {
    localStorage.removeItem('restaurantId');
    history.push(`/inventario/contagens/${paramsID}?prevPath=diaryCount`);
  };

  const handleBack = () => {
    localStorage.removeItem('restaurantId');

    const hasProductWithDiff = products.some(
      (product) => product.hasDiffTheoreticalRealStock
    );

    if (hasProductWithDiff) {
      setModalStates((prevState) => ({
        ...prevState,
        countDivergentCheckDialog: true
      }));
      return;
    }

    backFunction();
  };

  const KeyCheck = (e, isLast) => {
    const KeyID = window.e ? e.keyCode : e.keyCode;

    if (KeyID === 13 || KeyID === 9) {
      focusOnNextField(e, isLast, 200);
    }
  };


  const handleBlur = async (
    stringSummableValues,
    textValue,
    dataProps,
    e,
    isLast,
    disableTemporary = false
  ) => {
    const {
      product,
      sku,
      quantity,
      additional,
      skuQuantity,
      skuAdditional,
      inputType,
      provider,
      realStock: datapropsRealStock
    } = dataProps;
    if (product.realStock == null) setHasCountedOthersStocks(true);
    if (disableTemporary) setDisabledConfirmCount(true);
    let data = '';

    setLastProductCounted({
      productId: product.id,
      productDescription: product.description,
      realStock: formatCountValues(dataProps, textValue, products)?.realStock,
      theoreticalStock: product.theoricalStock,
      historical: product.historical
    });

    const productQuantity =
      additional || skuAdditional
        ? parseFloat(quantity)
        : formatCountValues(dataProps, textValue, products)?.total !== null
        ? formatCountValues(dataProps, textValue, products)?.total
        : null;

    data = {
      productId: product.id,
      quantity: isNan(productQuantity) ? null : productQuantity,
      skuId: provider.skuId,
      setupId,
      restaurantId: getId,
      providerId: provider.id,
      additional: !formatCountValues(dataProps, textValue, products) 
        ?.hasProductAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
        ? null
        : additional && !skuAdditional
        ? formatCountValues(dataProps, textValue, products)?.total
        : skuAdditional
        ? product.additional
        : isNumber(quantity) && !Number.isNaN(quantity)
        ? parseFloat(quantity)
        : null,
      realStock: formatCountValues(dataProps, textValue, products)?.realStock,
      unityAdditional: !formatCountValues(dataProps, textValue, products) 
        ?.hasSkuAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
        ? null
        : skuAdditional
        ? isNan(formatCountValues(dataProps, textValue, products)?.total)
          ? null
          : formatCountValues(dataProps, textValue, products)?.total
        : isNan(skuQuantity)
        ? null
        : skuQuantity,
      labelQuantity:
        skuAdditional || additional || productQuantity == null
          ? null
          : stringSummableValues,
      labelAdditional: !formatCountValues(dataProps, textValue, products)
        ?.hasProductAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
        ? null
        : additional
        ? formatCountValues(dataProps, textValue, products)?.addit !== null
          ? stringSummableValues
          : null
        : null,
      labelUnityAdditional: !formatCountValues(dataProps, textValue, products)
        ?.hasSkuAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
        ? null
        : !skuAdditional
        ? skuQuantity !== null && !isNan(skuQuantity)
          ? skuQuantity.toString()
          : null
        : isNan(formatCountValues(dataProps, textValue, products)?.total)
        ? null
        : formatCountValues(dataProps, textValue, products)?.total,
      user: userInfo?.user
    };

    const hasValues =
      data.quantity != null ||
      data.additional != null ||
      data.unityAdditional != null;

    if (hasValues) {
      addCountedProducts(data.productId, countedProducts);
    } else {
      removeCountedProducts(
        data.productId,
        countedProducts,
        setCountedProducts
      );
    }

    const oldCountedStocks = product.stocks.oldCountedStocks
      ? product.stocks.oldCountedStocks
      : product.stocks.countedStocks;
    let countedStocks = oldCountedStocks;

    if (hasValues && product.realStock == null) {
      countedStocks += 1;
    } else if (
      hasValues &&
      product.realStock == 0 &&
      product.stocks.totalStocks !== product.stocks.countedStocks
    ) {
      countedStocks = oldCountedStocks;
    } else if (
      !hasValues &&
      product.realStock !== null &&
      product.stocks.countedStocks > oldCountedStocks
    ) {
      countedStocks = oldCountedStocks;
    } else if (
      !hasValues &&
      product.realStock !== null &&
      product.stocks.countedStocks == oldCountedStocks
    ) {
      countedStocks -= 1;
    } else if (!hasValues && product.stocks.countedStocks < oldCountedStocks) {
      countedStocks = product.stocks.countedStocks;
    }
    updateProducts(
      {
        id: product.id,
        realStock: formatCountValues(dataProps, textValue, products)?.realStock,
        isAdditional: additional,
        additional: !formatCountValues(dataProps, textValue, products)
          ?.hasProductAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
          ? 0
          : additional && !skuAdditional
          ? parseFloat(formatCountValues(dataProps, textValue, products)?.total)
          : skuAdditional
          ? Number.isNaN(product.additional)
            ? 0
            : product.additional
          : parseFloat(quantity),
        isUnityAdditional: skuAdditional,
        unityAdditional: !formatCountValues(dataProps, textValue, products)
          ?.hasSkuAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
          ? 0
          : skuAdditional && !additional
          ? parseFloat(formatCountValues(dataProps, textValue, products)?.total)
          : parseFloat(skuQuantity),
        total: formatCountValues(dataProps, textValue, products)?.total
          ? parseFloat(formatCountValues(dataProps, textValue, products)?.total)
          : null,
        skuId: formatCountValues(dataProps, textValue, products).selectedSku.id,
        labelQuantity:
          skuAdditional ||
          additional ||
          formatCountValues(dataProps, textValue, products)?.total === null
            ? null
            : stringSummableValues,
        labelAdditional: !formatCountValues(dataProps, textValue, products)
          ?.hasProductAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
          ? 0
          : additional
          ? formatCountValues(dataProps, textValue, products)?.addit !== 0
            ? stringSummableValues
            : null
          : null,
        labelUnityAdditional: !formatCountValues(dataProps, textValue, products)
          ?.hasSkuAdditional && !formatCountValues(dataProps, textValue, products)?.hasProductConsumptionUnitSecondaryAdditional
          ? 0
          : skuAdditional
          ? formatCountValues(dataProps, textValue, products)?.skuAddit !== 0
            ? stringSummableValues
            : null
          : null,
        countedStocks,
        totalStocks: product.stocks.totalStocks,
        oldCountedStocks,
        providerId: provider.id
      },
      products,
      setProducts
    );

    setUpdateTotalDiffValues(Math.random());

    return axios
      .post(
        `${environments.catalog}/inventories/counts/${countId}/products/${product.id}?originId=${getId}`,
        data,
        config
      )
      .then(() => {
        const index = countedProducts.findIndex((x) => x === data.productId);
        const body = {
          productId: data.productId,
          order: index > -1 ? index : null,
          user: userInfo?.user
        };
        if (disableTemporary) setDisabledConfirmCount(false);

        axios
          .post(
            `${environments.catalog}/inventories/${countId}/assortment?originId=${getId}&setupId=${setupId}`,
            body,
            config
          )
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
        const index = countedProducts.findIndex((x) => x === data.productId);
        if (disableTemporary) setDisabledConfirmCount(false);

        if (!navigator.onLine) {
          saveSend(
            `${data.productId}-${data.skuId || ''}-${
              data.providerId
            }-${inputType}`,
            {
              ...data,
              order: index > -1 ? index : null,
              countType: inputType
            },
            `${environments.catalogV2}/inventories/counts/${countId}/products?originId=${getId}`
          );
          checkQueue(
            () => {
              location.reload();
            },
            () => {}
          );
        }
      })
      .finally(() => {
        setModalStates({
          ...modalStates,
          confirmCount: false
        });
      });
  };

  const handleCancelConfirmCount = () => {
    // handleBlur(
    //   modalConfirmCountObj.defaultValue,
    //   modalConfirmCountObj.defaultValue,
    //   modalConfirmCountObj.handleBlurCountObj,
    //   null,
    //   null,
    //   true
    // );
    setModalConfirmCountObj();
    setModalStates({
      ...modalStates,
      confirmCount: false
    });
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const dispatch = useDispatch();

  const checkBackQueue = (urlString) => {
    const modalContent = (
      <>
        <h2 style={{ marginBottom: '20px' }}>Atenção</h2>
        <p>
          Identificamos contagens registradas sem internet, aguarde o término do
          processamento antes de efetuar inclusões ou alterações nesta contagem.
        </p>
      </>
    );

    dispatch(defineTitle('Processando contagem offline...'));
    dispatch(define(modalContent));
    dispatch(open());
    {
      /* const interval = setInterval(() => {
       axios
         .get(urlString)
         .then((response) => {
           if (!response?.data?.processingOfflineCounts) {
             dispatch(close());
             clearInterval(interval);
           } else {
             const modalContentUpdate = (
               <>
                 <h2 style={{ marginBottom: '20px' }}>Atenção</h2>
                 <p>
                   Identificamos contagens registradas sem internet, aguarde o
                   término do processamento antes de efetuar inclusões ou
                   alterações nesta contagem.
                 </p>
                 <p>
                   O processamento está {response?.data?.progressOfflineCounts}%
                   concluído.
                 </p>
               </>
             );
             dispatch(define(modalContentUpdate));
             dispatch(open());
           }
         })
         .catch((error) => {
           console.log(error);
           clearInterval(interval);
         });
     }, 5000); */
    }
  };

  const checkIntegration = () => {
    axios
      .get(
        `${environments.catalog}/queue/sales/exists/?restaurantId=${getId}&date=${date}`
      )
      .then((response) => {
        setHasProcessingInProgress(response?.data);

        if (
          (response?.data?.existQueue === 'reprocessing' &&
            response?.data?.isToday) ||
          response?.data?.existQueue === 'reprocessing'
        ) {
          const modalContent = (
            <>
              <h2 style={{ marginBottom: '20px' }}>Atenção</h2>
              <p>
                Recebemos o envio de um volume alto de notas de vendas com dados
                históricos, este processo pode demorar e impactar o
                processamento do Consumo/Estoque Teórico para a data desta
                contagem.
              </p>
            </>
          );

          dispatch(
            defineTitle('INTEGRAÇÃO DE HISTORICO DE VENDAS EM ANDAMENTO...')
          );
          dispatch(define(modalContent));
          dispatch(open());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductList = () => {
    const isString = typeof filterSelectedProduct === 'string';

    if (countId) {
      setLoading(true);
      setProducts([]);

      const assortmentType = assortmentDate?.date
        ? `&assortmentDate=${assortmentDate.date}`
        : '&assortmentType=ALPHABETICAL';

      const productFilter = isString
        ? `&productDescription=${filterSelectedProduct}`
        : filterSelectedProduct?.id
        ? `&productId=${filterSelectedProduct?.id}`
        : '';

      axios
        .get(
          `${environments.catalog}/inventories/${countId}?originId=${getId}&countDate=${date}&categoryDescription=${countCategoryDescription}${assortmentType}${productFilter}`
        )
        .then((response) => {
          if (response?.data?.processingOfflineCounts) {
            checkBackQueue(
              `${environments.catalog}/inventories/${countId}?originId=${getId}&countDate=${date}&categoryDescription=${countCategoryDescription}${assortmentType}${productFilter}`
            );
          }
          setDiversion(response?.data?.diversion);
          const responseProducts =
            response?.data?.products?.map((product) => {
              if (product.unifiedSkus && product.skus.length > 1) {
                return {
                  ...product,
                  skus: unifiedSkus(product.skus)
                };
              }
              return product;
            }) || [];

            let newArr = responseProducts.map(el => (
              {
                id: el?.id,
                packages: []
              }
            ))

            const formattedProducts = 
              responseProducts?.map((product, i) => ({
                ...product,
                skus: product.skus.map((sku, i) => {
                  const providers = sku.providers.map((provider, i) => {
                    const packageFormatted = `E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`
                  
                    if (product?.ignoreBrands && newArr?.find(el => el?.id === product?.id)?.packages?.includes(packageFormatted)) {
                      return {
                        ...provider,
                        ignoreBrands: product?.ignoreBrands
                      };
                    }

                    if (newArr?.length) {
                      newArr = newArr?.map(el => {
                        if (el?.id === product?.id) {
                          return {
                            ...el,
                            packages: [...el.packages, packageFormatted]
                          }
                        }
  
                        return {
                          ...el
                        }
                      })
                    } 
    
                    return {
                      ...provider,
                      ignoreBrands: product?.ignoreBrands ? !product?.ignoreBrands : product?.ignoreBrands,
                    };
                  });
                  return {
                    ...sku,
                    providers
                  };
                })
              }))

          setLoading(false);

          if (resetFilters) {
            setEnabledFilterByDiff(['positive', 'negative', 'expected']);
            setResetFilters(false);
          }

          setPeriodSubtractDays(response.data.setup.periodicity.subtractDays);
          setPeriodicity(response.data.setup.periodicity);
          setStorageLocationDescription(
            response.data.setup.storageLocation.description
          );
          setCountDate(response.data.date);
          setStatus(response.data.status);
          setSetupId(response.data.setup.id);
          setProducts(formattedProducts);

          const additionals = response.data.products.reduce(
            (cnt, product) => cnt + product.skus.length,
            0
          );

          inputRef.current = new Array(
            response.data.products.length + additionals
          );
        })
        .catch((error) => {
          setLoading(false);
          setProducts([]);
          console.log(error);
        });
    }
  };

  const getReportInfos = () => {
    const isString = typeof filterSelectedProduct === 'string';
    if (periodicity?.id) {
      setProducts([]);
      setLoadingReportInfos(true);

      const assortmentType = assortmentDate?.date
        ? `&assortmentDate=${assortmentDate.date}`
        : '&assortmentType=ALPHABETICAL';

      const productFilter = isString
        ? `&productDescription=${filterSelectedProduct}`
        : filterSelectedProduct?.id
        ? `&productId=${filterSelectedProduct?.id}`
        : '';

      let url = undefined
      const canUseV2Counts = true

      if(canUseV2Counts) {
        url = `${environments.catalogV2}/inventories/counts/consolidated/${periodicity?.id}/${date}/${getId}?outdata=json&categoryDescription=${countCategoryDescription}${assortmentType}${productFilter}`
      } else {
        url = `${environments.catalog}/inventories/counts/consolidated/${periodicity?.id}/${date}/${getId}`
      }

      axios
        .get(url)
        .then((response) => {
          const responseObj = response?.data;
          const responseProducts =
            responseObj?.listProducts?.map((product) => ({
              ...product,
              consumptionUnitsPrimary: {
                abbreviation: product?.consumptionUnity
              }
            })) || [];

          setDiversion(response?.data?.diversionPeriodicity);
          setLoadingReportInfos(false);
          setDisableReportFirstReq(true);

          if (resetFilters) {
            setEnabledFilterByDiff(['positive', 'negative', 'expected']);
            setResetFilters(false);
          }

          setProducts(responseProducts);
        })
        .catch((error) => {
          setLoadingReportInfos(false);
          setProducts([]);
          console.log(error);
        });
    }
  };

  const getSalesInfos = useCallback(() => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedDateLessDays = moment(date)
      .subtract(periodSubtractDays, 'day')
      .format('YYYY-MM-DD');

    axios
      .get(
        `${environments.dashBoard}/cmvView/totals?restaurantIds=${paramsID}&startDate=${formattedDateLessDays}&endDate=${formattedDate}`
      )
      .then((response) => {
        setSalesInfos(response?.data || {});
      })
      .catch((error) => {
        setSalesInfos({});
        console.log(error);
      })
      .finally();
  }, [paramsID, periodSubtractDays]);

  const getDiscardInfos = useCallback(() => {
    const countIdFormatted = !countId
      ? countGroupInfos?.countGroup?.counts
          ?.map((el) => el?.id)
          ?.filter((el) => el)
      : countId;
    const formattedDate = moment(date)?.format('YYYY-MM-DD');
    const formattedDateLessDays = moment(date)
      .subtract(periodSubtractDays, 'day')
      .format('YYYY-MM-DD');

    axios
      .get(
        `${environments.dashBoard}/cmvView/discards?restaurantId=${getId}&startDate=${formattedDateLessDays}&endDate=${formattedDate}&countId=${countIdFormatted}`
      )
      .then((response) => {
        setInfoDiscard(response?.data?.sort((a, b) => a?.discardMonetary > b?.discardMonetary ? 1 : -1));
        const sum = response.data.reduce(
          (item, value) => item + value.discardMonetary,
          0
        );
        const products = response.data.length;
        setValuesDiscard({
          products,
          totalValue: sum
        });
      });
  }, [countId, periodSubtractDays]);

  const handleSaveCountConfig = (columnsFormatted) => {
    const body = {
      configs: [
        {
          counts: {
            showColumns: columnsFormatted
          }
        }
      ],
      user: userInfo?.user
    };

    axios
      .put(`${environments.auth}/users/${userInfo?.user}/config`, body)
      .then(() => {
        setShowColumns(columnsFormatted);
        dispatch(handleCountColumns({ configs: body?.configs }));
      })
      .catch((error) => {
        console.log('error', error);
        setToastOpen(true);
        setToastProps({
          message: 'Erro ao atualizar configurações',
          severity: 'error'
        });
      });
  };

  const openConfigPackageCount = (product) => {
    axios
      .get(
        `${environments.catalog}/inventories/counts/${countId}/packages/${product.id}?restaurantId=${getId}`
      )
      .then((response) => {
        setConfigPackageSkuItems(response.data.content);
        setModalStates((prevState) => ({
          ...prevState,
          modalConfigPackage: true
        }));
      })
      .catch((error) => {
        console.log('error =====>', error);
      });
  };

  const renderShowColumnItem = (column) => {
    const handleChangeColumnsView = (checked) => {
      const columnsFormatted = checked
        ? [...showColumns, column]
        : showColumns?.filter((el) => el !== column);

        const indexUser = columnsFormatted.findIndex(b => b === "USUÁRIO");
        const indexLast = columnsFormatted.findIndex(b => b === "ÚLTIMA");

        if(indexUser >= 0 && indexLast >= 0 && indexUser > indexLast)
          columnsFormatted.splice(indexLast, 1);
        
        if(indexUser >= 0 && indexLast >= 0 && indexUser < indexLast)
          columnsFormatted.splice(indexUser, 1);

        handleSaveCountConfig(columnsFormatted);
    };

    const showCurrentColumn = showColumns?.includes(column);

    return (
      <div className="dFlex alignCenter">
        <Checkbox
          style={{
            marginTop: 4,
            marginLeft: 0,
            color: '#FFF'
          }}
          name="View Mode"
          checked={showCurrentColumn}
          className="checkBoxSmall"
          onChange={(_, checked) => {
            handleChangeColumnsView(checked);
          }}
          size="small"
        />

        <label
          onClick={() => handleChangeColumnsView(!showCurrentColumn)}
          className="initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer 
           mb0
          "
          style={{
            lineHeight: 'normal',
            color: '#0F1225'
          }}
        >
          {column}
        </label>
      </div>
    );
  };

  const handleProductDialog = async (
    periodicity,
    storageLocation,
    disableOpenDialog = false
  ) => {
    const response = await fetch(
      `${environments.catalog}/inventories/setups/count/products?originId=${paramsID}&countId=${countId}`
    );
    const productsResponse = await response.json();

    if (productsResponse.length) {
      const uniqueProducts = [];
      productsResponse.map((pro) =>
        !uniqueProducts.find((x) => x.productId === pro.productId)
          ? uniqueProducts.push(pro)
          : null
      );
      setProductDialogList(uniqueProducts);
      setProductDialogTitle({ periodicity, storageLocation });
      setSelectedProduct('');

      !disableOpenDialog &&
        setModalStates((prevState) => ({
          ...prevState,
          productDialog: true
        }));
    }
  };

  const handleAddProduct = useCallback(async () => {
    try {
      await axios({
        method: 'post',
        url: `${environments.catalog}/products/${selectedProduct?.id}/setup/${setupId}`,
        data: {
          restaurantId: paramsID,
          date: countDate,
          user: userInfo.user,
          countId
        }
      });
      setToastOpen(true);
      setToastProps({
        message: 'Produto adicionado com sucesso',
        severity: 'success'
      });
      setSelectedProduct('');
      setModalStates({
        ...modalStates,
        confirmModal: false
      });
      handleProductDialog(
        periodicity?.description,
        storageLocationDescription,
        true
      );
      getProductList();
    } catch (error) {
      const isInternalProductError =
        error?.response?.data?.message ===
        'this product is not internal and does not have skus'
          ? 'Este produto não é interno e não possui skus'
          : 'Não foi possível adicionar o produto a contagem';

      setToastOpen(true);
      setToastProps({
        message: isInternalProductError,
        severity: 'error'
      });
      console.log(error);
    }
  }, [
    countDate,
    countId,
    getProductList,
    paramsID,
    selectedProduct?.id,
    setSelectedProduct,
    setToastOpen,
    setToastProps,
    setupId,
    userInfo.user
  ]);

  const handleRemoveProduct = useCallback(
    async (productId) => {
      const formattedDate = moment(countDate).format('YYYY-MM-DD');

      try {
        await axios({
          method: 'put',
          url: `${environments.catalog}/products/${productId}/setup/${setupId}/countDate/${formattedDate}?user=${userInfo.user}`
        });
        setToastOpen(true);
        setToastProps({
          message: 'Produto removido com sucesso',
          severity: 'success'
        });
        setSelectedProduct('');
        setModalStates({
          ...modalStates,
          confirmModal: false
        });
        handleProductDialog(
          periodicity?.description,
          storageLocationDescription,
          true
        );
        getProductList();
      } catch (error) {
        setToastOpen(true);
        setToastProps({
          message: 'Não foi possível remover o produto a contagem',
          severity: 'error'
        });
        console.log(error);
      }
    },
    [
      countDate,
      countId,
      getProductList,
      paramsID,
      selectedProduct?.id,
      setSelectedProduct,
      setToastOpen,
      setToastProps,
      setupId,
      userInfo.user
    ]
  );

  const putDiscard = () => {
    setLoadingDiscard(true);
    axios
      .post(`${environments.catalog}/extracts`, newData.discard)
      .then(() => {
        setToastOpen(true);
        setToastProps({
          message: 'Descarte lançado com sucesso',
          severity: 'success'
        });

        setModalStates({
          ...modalStates,
          modalDiscard: false
        });

        setTimeout(() => {
          getProductList();
          getDiscardInfos();
        }, 100);
      })
      .catch((error) => {
        setToastOpen(true);
        setToastProps({
          message: 'Não foi possível lançar o descarte',
          severity: 'error'
        });
        setModalStates({
          ...modalStates,
          modalDiscard: false
        });
        console.log('error', error);
      })
      .finally(() => setLoadingDiscard(false));
  };

  const handleClose = (modal) => {
    if (!modalToNotReloadList.includes(modal) && modal !== 'modalDiscard') {
      setLastProductCounted(null);
      modal !== 'modalProduct' && getProductList();
    }

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'extracts'
          ? {
              ...modal,
              open: false,
              startDate: '',
              endDate: '',
              extractType: '',
              currentRestaurant: ''
            }
          : modal
      )
    );

    setModalStates({ ...modalStates, [modal]: false });
  };

  const handleCloseNetwork = () => {
    setModalStates({ ...modalStates, countNetworkDialog: false });
  };

  const onConfirmConfirmationDialogButton = () => {
    if (confirmModalInfos?.name === 'addProduct') {
      handleAddProduct();
    }

    if (confirmModalInfos?.name === 'deleteProduct') {
      handleRemoveProduct(confirmModalInfos?.productId);
    }

    setConfirmModalInfos({
      name: '',
      contentText: '',
      productId: ''
    });

    setModalStates({
      ...modalStates,
      confirmModal: false
    });
  };

  const downloadReportConsolidated = (periodicityId, date) => {
    const uri = `${environments.catalog}/inventories/counts/reports/consolidated/${periodicityId}/${date}/${getId}`;

    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const diffPercentageTooltip = () => (
    <div className="dFlex flexColumn" style={{ borderRadius: 4 }}>
      <div
        className="bgLightBlue dFlex justifyCenter alignCenter mb16"
        style={{ padding: '10px 0', borderRadius: 4 }}
      >
        <p className="fontSizeDefault bold white mb0">ENTENDA AS DIFERENÇAS!</p>
      </div>

      <p
        className="fontSizeDefault bold white mb3 mr8"
        style={{ marginLeft: 14 }}
      >
        QTDE
      </p>

      <div
        className="bgWhite dFlex flexColumn mb16 mr8 ml8"
        style={{ borderRadius: 4, padding: 6 }}
      >
        <span className="fontSizeDefault mb3 bold" style={{ color: '#454A67' }}>
          QTDE = Estoque REAL (contagem) - Estoque TEÓRICO
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          Exemplo:
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          Estoque REAL = 11 un
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          Estoque TEÓRICO = 14 un
        </span>

        <span className="fontSizeDefault bold mb3" style={{ color: '#454A67' }}>
          EM QTDE (11 un - 14 un) = -3 un
        </span>
      </div>

      <p
        className="fontSizeDefault bold white mb3 mr8"
        style={{ marginLeft: 14 }}
      >
        % / CONSUMO
      </p>

      <div
        className="bgWhite dFlex flexColumn mb16 mr8 ml8"
        style={{ borderRadius: 4, padding: 6 }}
      >
        <span className="fontSizeDefault mb3 bold" style={{ color: '#454A67' }}>
          % / CONSUMO = Diferença EM QTDE / CONSUMO ESPERADO
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          Exemplo:
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          DIFERENÇA = -3 un
        </span>

        <span className="fontSizeDefault mb3" style={{ color: '#454A67' }}>
          CONSUMO ESPERADO = 1 un
        </span>

        <span className="fontSizeDefault bold mb3" style={{ color: '#454A67' }}>
          % / CONSUMO (-3 un / 1 un) = -300%
        </span>
      </div>

      <p
        className="fontSizeDefault bold white mb3 mr8"
        style={{ marginLeft: 14 }}
      >
        CORES X DIFERENÇAS
      </p>

      <div
        className="bgWhite dFlex flexColumn mb16 mr8 ml8"
        style={{ borderRadius: 4, padding: 6 }}
      >
        <span
          className="fontSizeDefault mb3 dFlex"
          style={{ color: '#454A67' }}
        >
          <span style={{ color: '#3CDD49', marginRight: 5 }}>VERDE</span>= Sem
          diferença ou diferença aceita
        </span>

        <span
          className="fontSizeDefault mb3 dFlex"
          style={{ color: '#454A67' }}
        >
          <span style={{ color: '#F2B90D', marginRight: 5 }}>AMARELO</span>=
          Diferença para mais
        </span>

        <span
          className="fontSizeDefault mb3 dFlex"
          style={{ color: '#454A67' }}
        >
          <span style={{ color: '#F53D4C', marginRight: 5 }}>VERMELHO</span>=
          Diferença para menos
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    checkIntegration();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setInBase(document?.querySelector('main').scrollTop < 50);
    };
    if (!document?.querySelector('main')) return;
    document?.querySelector('main')?.addEventListener('scroll', handleScroll);
    return () => {
      document
        ?.querySelector('main')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (status !== 'FINISHED') {
      const arr = products.filter(
        (product) =>
          product?.additional ||
          product?.skus?.some(
            (sku) =>
              sku?.unityAdditional || sku?.providers?.some((pro) => pro.stock)
          )
      );
      setCountedProducts(arr.map((value) => value.id));
    }
  }, [status]);

  useEffect(() => {
    (async () => {
      const request = await fetch(
        `${environments.restaurants}/parameters/${getId}`
      );
      const response = await request.json();
      const percentage =
        response && response.counts && response.counts.differencePercentage
          ? response.counts.differencePercentage
          : 0;

      setParametersInfos({
        ...response,
        percentageDifference: percentage
      });
    })();
  }, [getId]);

  useEffect(() => {
    if (secondParamsIdChange) {
      backFunction();
      return;
    }

    setSecondParamsIdChange(true);
    setGetId(paramsID);
  }, [paramsID]);

  useEffect(() => {
    if (assortmentDate) {
      setRadioValue('PHYSICAL');
    }
  }, [assortmentsOptions]);

  useEffect(() => {
    getProductList();
  }, [
    getId,
    countId,
    countCategoryDescription,
    assortmentDate,
    filterSelectedProduct
  ]);

  useEffect(() => {
    if (products?.length) {
      getSalesInfos();
    }
  }, [
    getId,
    countId,
    countCategoryDescription,
    assortmentDate,
    filterSelectedProduct,
    periodSubtractDays,
    products
  ]);

  useEffect(() => {
    if (
      Array.isArray(history.location.state?.currentActiveTab) &&
      !countId &&
      disableReportFirstReq
    ) {
      getReportInfos();
    }
  }, [countCategoryDescription, filterSelectedProduct]);

  useEffect(() => {
    if (reportTabObj?.active && !disableReportFirstReq) {
      getReportInfos();
    }
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      if (localStorage.getItem('queue') || !navigator.onLine) {
        setModalStates({ ...modalStates, countNetworkDialog: true });
      } else {
        backFunction();
      }
    };

    window.onbeforeunload = (event) => {
      if (localStorage.getItem('queue') || !navigator.onLine) {
        event.preventDefault();
        event.returnValue = '';
        const message =
          'Você está sem conexão com a internet. Se sair agora da página, perderá os dados já preenchidos. Recupere a conexão antes de sair.';
        (event || window.event).returnValue = message;
        return message;
      }
    };
  }, []);

  useEffect(() => {
    if (currentCountInfo.origin === 'INVENTORIES') {
      setLoading(true);
      axios
        .get(
          `${environments.catalog}/inventories/setup/${setupId}?originId=${getId}&user=${userInfo.user}&countDate=${date}&categoryDescription=${countCategoryDescription}`
        )
        .then((response) => {
          setLoading(false);

          if (!response || !response?.data || !response?.data?.products) {
            setProducts([]);
            setCountId([]);

            return;
          }

          const responseProducts = response.data.products.map((product) => {
            if (product.unifiedSkus && product.skus.length > 1) {
              return { ...product, skus: unifiedSkus(product.skus) };
            }

            return product;
          });

          setProducts(responseProducts);
          setCountId(response.data.id);

          const additionals = response.data.products.reduce(
            (cnt, product) => cnt + product.skus.length,
            0
          );
          inputRef.current = new Array(
            response.data.products.length + additionals
          );
        })
        .catch((error) => {
          setLoading(false);
          setProducts([]);
          console.log(error);
        });
    }
  }, [setupId, getId, radioValue, date, countCategoryDescription]);

  useEffect(() => {
    setIsLoadingAssortment(true);

    const currentCountId = countIdArray?.length ? countIdArray : countId;
    if (!differenceBetweenCountAndMenuAlert && currentCountId && countDate) {
      axios
        .get(
          `${environments.catalog}/inventories/compareMenu/${currentCountId}/${countDate}/${getId}`
        )
        .then((response) => {
          const hasDifferences =
            response?.data?.compared && response?.data?.hasDifferences;
          setDifferenceBetweenCountAndMenuAlert(hasDifferences);
          setLastExtractSale(response?.data?.lastExtractSale);
        })
        .catch((error) => {
          setDifferenceBetweenCountAndMenuAlert(false);
          console.log('error', error);
        });
    }

    if (countId && countDate) {
      axios
        .get(
          `${environments.catalog}/inventories/assortment?originId=${getId}&setupId=${setupId}&countDate=${countDate}`
        )
        .then((response) => {
          setAssortmentsOptions(response.data);
          setIsLoadingAssortment(false);
        })
        .catch((error) => {
          console.log(error);
          setAssortmentsOptions([]);
          setIsLoadingAssortment(false);
        });
    }
  }, [getId, countId, countDate, environments.catalog]);

  useEffect(() => {
    getDiscardInfos();
  }, [countId]);

  useEffect(() => {
    // Quando tiver periodicidade e não tiver infos de grupo das contagens, vamos buscar no endpoint
    if((periodicity && periodicity.id) && countGroupInfos.isCountGroup === false){
      const periodicityId = periodicity.id

      axios.get(`${environments.catalog}/periodicities/byDate?restaurantId=${getId}&startDate=${countDate}&finishDate=${countDate}&periodicityId=${periodicityId}`)
      .then(response => {
        if(response && response.data){
          if(response.data.content){
            if(response.data.content[0]){
              if(response.data.content[0]?.counts && Array.isArray(response.data.content[0].counts)){
                const counts = response.data.content[0].counts

                setCountGroupInfos({
                  isCountGroup: true,
                  countGroup: {
                    ...history.location.state?.countGroup,
                    counts: [
                      counts.map((count, countIndex) => {
                        if (countId == count?.id) {
                          return {
                            ...count,
                            active: true
                          };
                        }
                        return {
                          ...count,
                          active: false
                        };
                      }),
                      reportTabObj
                    ].flat()
                  }
                })
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }, [periodicity])

  return (
    <Layout>
      <GlobalStyle />

      <Container>
          <div className="dailyCounts">
            {differenceBetweenCountAndMenuAlert && (
              <span
                className="dFlex justifyCenter alignCenter white bold fontSizeDefault mb5 textAlignCenter"
                style={{
                  background: '#F53D4C',
                  borderRadius: '4px',
                  lineHeight: 1.6,
                  zIndex: 9999,
                  position: 'sticky',
                  top: 0,
                }}
              >
                ATENÇÃO! Houve uma falha no processamento. Podem haver divergências, as diferenças não devem ser consideradas válidas. Estamos atuando para normalizar... 
              </span>
            )}
            
            <CountHeader
              differenceBetweenCountAndMenuAlert={differenceBetweenCountAndMenuAlert}
              lastExtractSale={lastExtractSale}
              updateTotalDiffValues={updateTotalDiffValues}
              filterOnlyNotCounted={filterOnlyNotCounted}
              enabledFilterByDiff={enabledFilterByDiff}
              orderByQuantityDesc={orderByQuantityDesc}
              getId={getId}
              products={products}
              countDate={countDate}
              periodicityDescription={periodicity?.description}
              storageLocationDescription={storageLocationDescription}
              countCategoryDescription={countCategoryDescription}
              setCountCategoryDescription={setCountCategoryDescription}
              filterSelectedProduct={filterSelectedProduct}
              setFilterSelectedProduct={setFilterSelectedProduct}
              countGroupInfos={countGroupInfos}
              smallScreen={smallScreen}
              setEnabledFilterByDiff={setEnabledFilterByDiff}
              setProducts={setProducts}
              setFilterOnlyNotCounted={setFilterOnlyNotCounted}
              isSelectedRealDiff={isSelectedRealDiff}
              setOrderByQuantityDesc={setOrderByQuantityDesc}
              salesInfos={salesInfos}
              isReportTabActive={reportTabInfos?.active}
              hasProcessingInProgress={hasProcessingInProgress}
              setModalStates={setModalStates}
              modalStates={modalStates}
              valuesDiscard={valuesDiscard}
              setRadioValue={setRadioValue}
              radioValue={radioValue}
              date={date}
              countId={countId}
              assortmentDate={assortmentDate}
              setLoading={setLoading}
              assortmentsOptions={assortmentsOptions}
              setAssortmentDate={setAssortmentDate}
            />

            <div
              className="fakeWhiteBg dFlex alignCenter bgWhite pFixed"
              style={{
                height: countGroupInfos?.isCountGroup 
                  ? differenceBetweenCountAndMenuAlert
                    ? smallScreen
                      ? 340
                      : 140
                    : smallScreen 
                      ? 301
                      : 120 
                  : 70,
                width: smallScreen ? '96.6%' : '95.8%',
                top: smallScreen ? '0px' : '64px',
                zIndex: '99'
              }}
            />

            <GroupHeaderContainer showAlert={differenceBetweenCountAndMenuAlert}>
              <GroupHeader>
                <CountGroupCarousel
                  getProductList={getProductList}
                  getReportInfos={getReportInfos}
                  setCountId={setCountId}
                  setCountIdArray={setCountIdArray}
                  setCountGroupInfos={setCountGroupInfos}
                  countGroupInfos={countGroupInfos}
                  inBase={inBase}
                  loading={loading}
                  loadingReportInfos={loadingReportInfos}
                />
              </GroupHeader>

              <div className="dFlex justifyEnd" style={{ width: "27%" }}>
                <div
                  className={`subtitle ${!showColumns.filter((el) => el.includes('DIFERENÇA'))?.length &&
                    'dNone'
                    }`}
                  style={{ paddingLeft: 1 }}
                >
                  <span
                    className="bold dFlex w100 alignCenter justifyBetween spanDesk"
                    style={{
                      lineHeight: 1.6,
                      borderRadius: '4px 4px 0px 0px',
                    }}
                  >
                    <div
                      className="dFlex fontSizeDefault alignCenter w100"
                      style={{
                        color: '#010311',
                        lineHeight: '16px',
                      }}
                    >
                      <span className="dFlex justifyCenter alignCenter" style={{ color: "#0B1A8E", width: '50%' }}>
                        Diferenças

                        <Tooltip
                          TransitionProps={{ timeout: 600 }}
                          title={diffPercentageTooltip()}
                          placement="left"
                        >
                          <img
                            style={{ width: '12px' }}
                            src={InfoQuestionIcon}
                            alt="InfoQuestionIcon"
                            className="ml5"
                          />
                        </Tooltip>
                      </span>
                      
                      <div className="dFlex justifyEnd">
                        <div className="dFlex ml10">
                          <SelectButton
                            isSelected={isSelectedQuantity}
                            setIsSelected={() =>
                              handleSelectedRealDiff(
                                'qtde',
                                orderByQuantityDesc,
                                setProducts,
                                setIsSelectedRealDiff,
                                setIsSelectedQuantity,
                                setIsSelectedCost
                              )
                            }
                            radioButton
                          />
                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                            Qtde.
                          </span>
                        </div>

                        <div className="dFlex">
                          <SelectButton
                            isSelected={isSelectedCost}
                            setIsSelected={() =>
                              handleSelectedRealDiff(
                                'cost',
                                orderByQuantityDesc,
                                setProducts,
                                setIsSelectedRealDiff,
                                setIsSelectedQuantity,
                                setIsSelectedCost
                              )
                            }
                            radioButton
                          />
                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                            R$
                          </span>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span
                    className="bold dFlex w100 alignCenter justifyCenter spanTablet"
                    style={{
                      color: "#0B1A8E",
                      lineHeight: 1.6,
                      borderRadius: '4px 4px 0px 0px',
                    }}
                  >
                    Diferenças.
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      title={diffPercentageTooltip()}
                      placement="left"
                    >
                      <img
                        style={{ width: '12px' }}
                        src={InfoQuestionIcon}
                        alt="InfoQuestionIcon"
                        className="ml5"
                      />
                    </Tooltip>
                  </span>
                </div>

                {showColumns?.includes('USUÁRIO') &&
                  <th
                    className='spanDesk'
                    width="5%"
                  />
                }
              </div>
            </GroupHeaderContainer>

            <Table
              className="desktop pRelative"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <DiaryCountTableHead
                isSelectedCost={isSelectedCost}
                countGroupInfos={countGroupInfos}
                handleProductDialog={handleProductDialog}
                setModalStates={setModalStates}
                periodicityDescription={periodicity?.description}
                periodicityId={periodicity?.id}
                storageLocationDescription={storageLocationDescription}
                products={products}
                countDate={countDate}
                parametersInfos={parametersInfos}
                differenceBetweenCountAndMenuAlert={
                  differenceBetweenCountAndMenuAlert
                }
                downloadReportConsolidated={downloadReportConsolidated}
                handlePopoverClick={handlePopoverClick}
                showColumns={showColumns}
                smallScreen={smallScreen}
              />

              <tbody id="tbodyElement">
                {reportTabInfos?.active ? (
                  products?.length && !loadingReportInfos ? (
                    <ReportTableBody
                      getId={getId}
                      isSelectedCost={isSelectedCost}
                      setModalStates={setModalStates}
                      modalStates={modalStates}
                      setParameters={setParameters}
                      setProductModal={setProductModal}
                      setProductDescription={setProductDescription}
                      products={products}
                      parametersInfos={parametersInfos}
                      hasCountedOthersStocks={hasCountedOthersStocks}
                      isSelectedRealDiff={isSelectedRealDiff}
                      countDate={countDate}
                      showColumns={showColumns}
                      setRealStockModal={setRealStockModal}
                      realStockModal={realStockModal}
                      setModalSettings={setModalSettings}
                      modalSettings={modalSettings}
                    />
                  ) : (
                    <tr>
                      <th
                        colSpan={reportTabInfos?.active ? '10' : '13'}
                        style={{ margin: '10px', textAlign: 'center' }}
                      >
                        Nenhum item encontrado
                      </th>
                    </tr>
                  )
                ) : products?.length ? (
                  products?.map((product, productIndex) => {
                    const {
                      id,
                      consumptionUnitsPrimary: {
                        abbreviation: productConsumptionUnit
                      }
                    } = product;
                    const skus = setSkus(product);
                    
                    const showProductDescription = assembleShowProducts(
                      mapShowProductDescription(skus)
                    );
                    const kgElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'kg'
                      );
                    const gElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'g'
                      );
                    const mgElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'mg'
                      );
                    const ltElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'lt'
                      );
                    const mlElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'ml'
                      );
                    const unElements = skus
                      ?.sort((a, b) =>
                        a?.providers[0]?.quantity <= b?.providers[0]?.quantity
                          ? 1
                          : -1
                      )
                      ?.filter(
                        (el) => el?.unitsMeasurements?.abbreviation === 'un'
                      );
                    const orderedSkus = [
                      kgElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      ),
                      gElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      ),
                      mgElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      ),
                      ltElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      ),
                      mlElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      ),
                      unElements.sort((a, b) =>
                        a?.quantity <= b?.quantity ? 1 : -1
                      )
                    ].flat();

                    const consumptionUnitsSecondary = product?.consumptionUnitsSecondary

                    const notRenderProductE1 = 
                      orderedSkus?.some((sku) =>
                        sku?.providers?.some(
                          (provider) => provider?.quantity === 1 && 
                          product?.consumptionUnitsPrimary?.abbreviation == 'un'
                        )
                      )

                    const renderProductAdditional =
                      ((orderedSkus?.some((sku) =>
                        sku?.providers?.some(
                          (provider) => provider.quantity > 1
                        )
                      ) ||
                        (orderedSkus?.some((sku) => sku.quantity !== 1)) &&
                      (product.consumptionUnitsPrimary.abbreviation ||
                        consumptionUnitsSecondary)))

                    const renderProductSecondaryUnitAdditional = product?.consumptionUnitsPrimary?.abbreviation === 'un' 
                      && (consumptionUnitsSecondary?.abbreviation === 'lt' || consumptionUnitsSecondary?.abbreviation === 'kg' )
                      && product?.skus?.length

                    const allPackages = orderedSkus?.map(skuItem => 
                      skuItem?.providers?.map(providerItem => 
                        `E${providerItem?.quantity || 0}x${skuItem?.quantity || 0}${skuItem?.unitsMeasurements?.abbreviation || ''}` 
                      )
                    ).flat()

                    const hideProductAdditional = product?.ignoreBrands && allPackages?.includes('E1x1kg')

                    const showOnlyFakeAdditional = orderedSkus?.length === 1 ? !orderedSkus[0]?.providers[0]?.showOnCount && product?.fakeAdditional : false

                    return (
                      <div
                        id={`productGroup-${productIndex}`}
                        className={`singleCountItem ${`n${productIndex}`} ${
                          product?.hide ? 'dNone' : ''
                        }`}
                      >
                        {orderedSkus.map((sku, skuIndex) => {
                          const orderedProviders = sku?.providers?.sort(
                            (a, b) => (a.quantity < b.quantity ? 1 : -1)
                          );
                          const providers = setProviders(
                            sku,
                            orderedProviders,
                            productConsumptionUnit,
                            product,
                            products
                          );

                          return (
                            <ProductListItem
                              setCurrentInput={setCurrentInput}
                              setUpdateValueByModal={setUpdateValueByModal}
                              openConfigPackageCount={openConfigPackageCount}
                              updateValueByModal={updateValueByModal}
                              disabledConfirmCount={disabledConfirmCount}
                              id={id}
                              countId={countId}
                              getId={getId}
                              confirmModalAction={confirmModalAction}
                              setModalConfirmCountObj={setModalConfirmCountObj}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              setParameters={setParameters}
                              setProductModal={setProductModal}
                              setProductDescription={setProductDescription}
                              providers={providers}
                              product={product}
                              showProductDescription={showProductDescription}
                              skuIndex={skuIndex}
                              parametersInfos={parametersInfos}
                              hasCountedOthersStocks={hasCountedOthersStocks}
                              setCurrentCountDivergentDialogData={
                                setCurrentCountDivergentDialogData
                              }
                              isSelectedRealDiff={isSelectedRealDiff}
                              skus={skus}
                              sku={sku}
                              productIndex={productIndex}
                              orderedSkus={orderedSkus}
                              renderProductAdditional={renderProductAdditional && !notRenderProductE1 && !hideProductAdditional && !renderProductSecondaryUnitAdditional}
                              KeyCheck={KeyCheck}
                              handleBlur={handleBlur}
                              isLoadingAssortment={isLoadingAssortment}
                              internalProduct={products}
                              countDate={countDate}
                              setNewData={setNewData}
                              lastProductCounted={lastProductCounted}
                              userInfo={userInfo}
                              setLastProductCounted={setLastProductCounted}
                              countGroupInfos={countGroupInfos}
                              showColumns={showColumns}
                            />
                          );
                        })}

                        {renderProductAdditional && !notRenderProductE1 && !hideProductAdditional && !renderProductSecondaryUnitAdditional ? (
                          <tr index={productIndex}>
                            <td
                              width="20"
                              className="description"
                              style={{ paddingTop: 3, paddingLeft: 10 }}
                            >
                              {showOnlyFakeAdditional ?
                                <>
                                  <div
                                    className="second-line dFlex justifyBetween"
                                  >
                                    {ValidationLength(
                                      product.description,
                                      product.type === 'INTERNAL' ? 48 : 30
                                    )}

                                    <div className="dFlex alignCenter">
                                      {product.type !== 'INTERNAL' && (
                                        <div className="dFlex justifyBetween alignCenter ml10 mr5">
                                          {product.hasDiffTheoreticalRealStock && (
                                            <Tooltip
                                              title={
                                                <div>
                                                  <p>DIVERGÊNCIA</p>
                                                  <p>Clique para resolver</p>
                                                </div>
                                              }
                                            >
                                              <img
                                                src={infoRedIcon}
                                                alt="Info Icon"
                                                style={{
                                                  marginRight: 5,
                                                  cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                  setCurrentCountDivergentDialogData({
                                                    title: product.description,
                                                    productId: product.id,
                                                    countDate
                                                  });
                                                  setModalStates((prevState) => ({
                                                    ...prevState,
                                                    countDivergentDialog: true
                                                  }));
                                                }}
                                              />
                                            </Tooltip>
                                          )}

                                          <img
                                            src={configPackageCount}
                                            alt="configPackageCount"
                                            onClick={() => openConfigPackageCount(product)}
                                            style={{
                                              width: '18px',
                                              cursor: 'pointer'
                                            }}
                                          />
                                        </div>
                                      )}

                                      <Tooltip title="Lançar descarte">
                                        <IconButton
                                          className="mr5"
                                          size="small"
                                          onClick={() => {
                                            setModalStates((prevState) => ({
                                              ...prevState,
                                              modalDiscard: true
                                            }));
                                            setNewData((prevState) => ({
                                              ...prevState,
                                              data: {
                                                description: product?.description,
                                                originId: getId,
                                                unitsMeasurementsId:
                                                  product?.consumptionUnitsPrimary?.id,
                                                unitsMeasurementsAbbreviation:
                                                  product?.consumptionUnitsPrimary?.abbreviation,
                                                unitsMeasurementsSecondaryId:
                                                  product?.consumptionUnitsSecondary?.id,
                                                unitsMeasurementsSecondaryAbbreviation:
                                                  product?.consumptionUnitsSecondary?.abbreviation
                                              },
                                              discard: [
                                                {
                                                  id: product?.id,
                                                  typeEvent: 'DISCARD',
                                                  cost: product?.cost,
                                                  originId: getId,
                                                  quantity: product?.quantity,
                                                  eventDate: format(
                                                    new Date(countDate),
                                                    'yyyy-MM-dd'
                                                  ),
                                                  un: product?.consumptionUnitsPrimary?.abbreviation,
                                                  unSecondary: product?.consumptionUnitsSecondary?.abbreviation,
                                                  user: userInfo.user
                                                }
                                              ]
                                            }));
                                          }}
                                        >
                                          <Delete
                                            style={{
                                              width: 15,
                                              height: 15,
                                              color: '#313347'
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  
                                  <div className='h100' />
                                </>
                              : ''}
                            </td>

                            <td
                              width="30"
                              className="description p8"
                              style={{
                                paddingLeft: 10,
                                borderTop: '1px solid #ccc'
                              }}
                            >
                              {product?.fakeAdditional ?
                                <div className="dFlex alignCenter">
                                  <Tooltip
                                    arrow
                                    disableHoverListener={!orderedSkus[0]?.images?.url}
                                    placement="right"
                                    title={
                                      <div
                                        className={`skuImgPreview cropDataSmallBox mr0 ${orderedSkus[0]?.skuBloom?.images?.url && 'bgWhite'
                                          } largeSize`}
                                          style={{ background: "#fff" }}
                                      >
                                        <img src={orderedSkus[0]?.images?.url} alt="Sku Preview" />
                                      </div>
                                    }
                                  >
                                    <div className="dFlex alignCenter justifyCenter" style={{ height: 24, width: 24 }}>
                                      <img
                                        src={orderedSkus[0]?.images?.url || HasNotImage}
                                        alt={orderedSkus[0]?.images?.url ? 'HasImage' : 'No Sku Preview'}
                                        style={{
                                          width: 'auto',
                                          margin: 'auto',
                                          height: 'auto',
                                          maxHeight: '100%'
                                        }}
                                      />
                                    </div>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      <p className="mb0" style={{ padding: '2px 6px' }}>
                                        {orderedSkus[0].description}
                                      </p>
                                    }
                                    placement="right"
                                    >
                                    <div className={orderedSkus[0]?.images?.url ? 'ml5' : 'ml8'}>
                                      {orderedSkus[0].infoToShow
                                        ? ValidationLength(orderedSkus[0].infoToShow, 18)
                                        : null}
                                    </div>
                                  </Tooltip>
                                </div>
                              : 
                                <>
                                  Adicional em{' '}
                                  {consumptionUnitsSecondary?.abbreviation || product?.consumptionUnitsPrimary?.abbreviation}
                                </>
                              }
                            </td>

                            <TdCount
                              width="3"
                              className="description"
                              style={{
                                borderTop: '1px solid #ccc'
                              }}
                            >
                              E1x1
                              {consumptionUnitsSecondary?.abbreviation || product?.consumptionUnitsPrimary?.abbreviation}
                            </TdCount>

                            <TdEmb
                              width="3"
                              className="description"
                              style={{
                                borderTop: '1px solid #ccc',
                                padding: '0 10px'
                              }}
                            >
                              E1x1
                              {consumptionUnitsSecondary?.abbreviation || product?.consumptionUnitsPrimary?.abbreviation}
                              <FormControl fullWidth>
                                <CurrencyTextFieldSumeValues
                                  setCurrentInput={setCurrentInput}
                                  updatedValue={updateValueByModal}
                                  setUpdateValueByModal={setUpdateValueByModal}
                                  sourcePage="countPage"
                                  id={`additionalInput-${productIndex}-tablet`}
                                  isLast
                                  key={productIndex}
                                  name="contagem"
                                  disabled={
                                    isLoadingAssortment || disabledConfirmCount
                                  }
                                  placeholder={product?.fakeAdditional ? "Contagem" : "Adicional"}
                                  onKeyDown={KeyCheck}
                                  confirmModalAction={confirmModalAction}
                                  parametersInfos={parametersInfos}
                                  product={product}
                                  products={products}
                                  modalStates={modalStates}
                                  setModalConfirmCountObj={
                                    setModalConfirmCountObj
                                  }
                                  setModalStates={setModalStates}
                                  countObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  handleBlurCountObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  onBlur={(
                                    stringSummableValues,
                                    replacedValue,
                                    e,
                                    isLast
                                  ) => {
                                    const obj = {
                                      product,
                                      sku: product?.skus[0],
                                      inputType: 'add',
                                      quantity:
                                        product?.skus[0]?.providers[0]?.stock,
                                      additional: true,
                                      skuQuantity:
                                        product?.skus[0]?.unityAdditional,
                                      skuAdditional: false,
                                      provider: product?.skus[0]?.providers
                                        ?.length
                                        ? product?.skus[0]?.providers[0]
                                        : null,
                                      locationProps: {
                                        skus: skus.length,
                                        skuIndex: 'add'
                                      }
                                    };

                                    setLastProductCounted({
                                      productId: product.id,
                                      productDescription: product.description,
                                      realStock: product.realStock,
                                      theoreticalStock: product.theoricalStock,
                                      historical: product.historical
                                    });
                                    handleBlur(
                                      stringSummableValues,
                                      replacedValue,
                                      obj,
                                      e,
                                      isLast
                                    );
                                  }}
                                  onFocus={() =>
                                    handleFocus(
                                      product,
                                      lastProductCounted,
                                      parametersInfos,
                                      countId,
                                      getId,
                                      userInfo
                                    )
                                  }
                                  onFocusProps={{
                                    product
                                  }}
                                  defaultValue={
                                    product?.labelAdditional ||
                                    product?.additional ||
                                    ''
                                  }
                                />
                              </FormControl>
                            </TdEmb>

                            <TdCount
                              width="8"
                              className="description right p8"
                              style={{
                                borderTop: '1px solid #ccc',
                                borderRight: '1px solid #ccc'
                              }}
                              id="addField"
                            >
                              <FormControl fullWidth>
                                <CurrencyTextFieldSumeValues
                                  setCurrentInput={setCurrentInput}
                                  setUpdateValueByModal={setUpdateValueByModal}
                                  updatedValue={product}
                                  sourcePage="countPage"
                                  id={`additionalInput-${productIndex}`}
                                  isLast
                                  key={productIndex}
                                  name="contagem"
                                  disabled={
                                    isLoadingAssortment || disabledConfirmCount
                                  }
                                  placeholder={product?.fakeAdditional ? "Contagem" : "Adicional"}
                                  onKeyDown={KeyCheck}
                                  parametersInfos={parametersInfos}
                                  product={product}
                                  confirmModalAction={confirmModalAction}
                                  products={products}
                                  modalStates={modalStates}
                                  setModalConfirmCountObj={
                                    setModalConfirmCountObj
                                  }
                                  setModalStates={setModalStates}
                                  countObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  handleBlurCountObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  onBlur={(
                                    stringSummableValues,
                                    replacedValue,
                                    e,
                                    isLast
                                  ) => {
                                    const obj = {
                                      product,
                                      sku: product?.skus[0],
                                      inputType: 'add',
                                      quantity:
                                        product?.skus[0]?.providers[0]?.stock,
                                      additional: true,
                                      skuQuantity:
                                        product?.skus[0]?.unityAdditional,
                                      skuAdditional: false,
                                      provider: product?.skus[0]?.providers
                                        ?.length
                                        ? product?.skus[0]?.providers[0]
                                        : null,
                                      locationProps: {
                                        skus: skus.length,
                                        skuIndex: 'add'
                                      }
                                    };

                                    setLastProductCounted({
                                      productId: product.id,
                                      productDescription: product.description,
                                      realStock: product.realStock,
                                      theoreticalStock: product.theoricalStock,
                                      historical: product.historical
                                    });
                                    handleBlur(
                                      stringSummableValues,
                                      replacedValue,
                                      obj,
                                      e,
                                      isLast
                                    );
                                  }}
                                  onFocus={() =>
                                    handleFocus(
                                      product,
                                      lastProductCounted,
                                      parametersInfos,
                                      countId,
                                      getId,
                                      userInfo
                                    )
                                  }
                                  onFocusProps={{
                                    product
                                  }}
                                  defaultValue={
                                    product?.labelAdditional ||
                                    product?.additional ||
                                    ''
                                  }
                                />
                              </FormControl>
                            </TdCount>

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes('OUTROS ESTOQUES') &&
                                'dNone'
                              }`}                  
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('REAL') && 'dNone'
                              }`}
                            />

                            <td
                              width="2"
                              className={`description right p8 ${
                                !showColumns?.includes('TEÓRICO') && 'dNone'
                              }`}
                              style={{
                                borderRight: '1px double #BFC0CB!important'
                              }}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('ÚLTIMA') && 'dNone'
                              }`}
                            />

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes('CONSUMO') && 'dNone'
                              }`}
                            />

                            {/* <td width="5" className="description right p8"></td> */}

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes(
                                  'DIFERENÇA EM QTDE / R$'
                                ) && 'dNone'
                              }`}
                              style={{ borderLeft: 'solid 1px #BFC0CB' }}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('DIFERENÇA EM %') &&
                                'dNone'
                              }`}
                              style={{ borderLeft: 'solid 1px #BFC0CB' }}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('USUÁRIO') &&
                                'dNone'
                              }`}
                              style={{ borderLeft: 'solid 1px #BFC0CB' }}
                            />
                          </tr>
                        ) : null}

                        {renderProductSecondaryUnitAdditional && !hideProductAdditional ?
                          <tr index={productIndex}>
                            <td
                              width="20"
                              className="description"
                              style={{ paddingTop: 3 }}
                            />

                            <td
                              width="30"
                              className="description p8"
                              style={{
                                paddingLeft: 10,
                                borderTop: '1px solid #ccc'
                              }}
                            >
                              Adicional em{' '}
                              {consumptionUnitsSecondary?.abbreviation}
                            </td>

                            <TdCount
                              width="3"
                              className="description"
                              style={{
                                borderTop: '1px solid #ccc'
                              }}
                            >
                              E1x1
                              {consumptionUnitsSecondary?.abbreviation}
                            </TdCount>

                            <TdEmb
                              width="3"
                              className="description"
                              style={{
                                borderTop: '1px solid #ccc',
                                padding: '0 10px'
                              }}
                            >
                              E1x1
                              {consumptionUnitsSecondary?.abbreviation}
                              <FormControl fullWidth>
                                <CurrencyTextFieldSumeValues
                                  setCurrentInput={setCurrentInput}
                                  updatedValue={updateValueByModal}
                                  setUpdateValueByModal={setUpdateValueByModal}
                                  sourcePage="countPage"
                                  id={`additionalInputSecondaryUnit-${productIndex}-tablet`}
                                  isLast
                                  key={productIndex}
                                  name="contagem"
                                  disabled={
                                    isLoadingAssortment || disabledConfirmCount
                                  }
                                  placeholder="Adicional"
                                  onKeyDown={KeyCheck}
                                  confirmModalAction={confirmModalAction}
                                  parametersInfos={parametersInfos}
                                  product={product}
                                  products={products}
                                  modalStates={modalStates}
                                  setModalConfirmCountObj={
                                    setModalConfirmCountObj
                                  }
                                  setModalStates={setModalStates}
                                  countObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  handleBlurCountObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  onBlur={(
                                    stringSummableValues,
                                    replacedValue,
                                    e,
                                    isLast
                                  ) => {
                                    const obj = {
                                      product,
                                      sku: product?.skus[0],
                                      inputType: 'add',
                                      quantity:
                                        product?.skus[0]?.providers[0]?.stock,
                                      additional: true,
                                      skuQuantity:
                                        product?.skus[0]?.unityAdditional,
                                      skuAdditional: false,
                                      provider: product?.skus[0]?.providers
                                        ?.length
                                        ? product?.skus[0]?.providers[0]
                                        : null,
                                      locationProps: {
                                        skus: skus.length,
                                        skuIndex: 'add'
                                      }
                                    };

                                    setLastProductCounted({
                                      productId: product.id,
                                      productDescription: product.description,
                                      realStock: product.realStock,
                                      theoreticalStock: product.theoricalStock,
                                      historical: product.historical
                                    });
                                    handleBlur(
                                      stringSummableValues,
                                      replacedValue,
                                      obj,
                                      e,
                                      isLast
                                    );
                                  }}
                                  onFocus={() =>
                                    handleFocus(
                                      product,
                                      lastProductCounted,
                                      parametersInfos,
                                      countId,
                                      getId,
                                      userInfo
                                    )
                                  }
                                  onFocusProps={{
                                    product
                                  }}
                                  defaultValue={
                                    product?.labelAdditional ||
                                    product?.additional ||
                                    ''
                                  }
                                />
                              </FormControl>
                            </TdEmb>

                            <TdCount
                              width="8"
                              className="description right p8"
                              style={{
                                borderTop: '1px solid #ccc',
                                borderRight: '1px solid #ccc'
                              }}
                              id="addField"
                            >
                              <FormControl fullWidth>
                                <CurrencyTextFieldSumeValues
                                  setCurrentInput={setCurrentInput}
                                  setUpdateValueByModal={setUpdateValueByModal}
                                  updatedValue={product}
                                  sourcePage="countPage"
                                  id={`additionalInputSecondaryUnit-${productIndex}`}
                                  isLast
                                  key={productIndex}
                                  name="contagem"
                                  disabled={
                                    isLoadingAssortment || disabledConfirmCount
                                  }
                                  placeholder="Adicional"
                                  onKeyDown={KeyCheck}
                                  parametersInfos={parametersInfos}
                                  product={product}
                                  confirmModalAction={confirmModalAction}
                                  products={products}
                                  modalStates={modalStates}
                                  setModalConfirmCountObj={
                                    setModalConfirmCountObj
                                  }
                                  setModalStates={setModalStates}
                                  countObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  handleBlurCountObj={{
                                    product,
                                    sku: product?.skus[0],
                                    inputType: 'add',
                                    quantity:
                                      product?.skus[0]?.providers[0]?.stock,
                                    additional: true,
                                    skuQuantity:
                                      product?.skus[0]?.unityAdditional,
                                    skuAdditional: false,
                                    provider: product?.skus[0]?.providers
                                      ?.length
                                      ? product?.skus[0]?.providers[0]
                                      : null,
                                    locationProps: {
                                      skus: skus.length,
                                      skuIndex: 'add'
                                    }
                                  }}
                                  onBlur={(
                                    stringSummableValues,
                                    replacedValue,
                                    e,
                                    isLast
                                  ) => {
                                    const obj = {
                                      product,
                                      sku: product?.skus[0],
                                      inputType: 'add',
                                      quantity:
                                        product?.skus[0]?.providers[0]?.stock,
                                      additional: true,
                                      skuQuantity:
                                        product?.skus[0]?.unityAdditional,
                                      skuAdditional: false,
                                      provider: product?.skus[0]?.providers
                                        ?.length
                                        ? product?.skus[0]?.providers[0]
                                        : null,
                                      locationProps: {
                                        skus: skus.length,
                                        skuIndex: 'add'
                                      }
                                    };

                                    setLastProductCounted({
                                      productId: product.id,
                                      productDescription: product.description,
                                      realStock: product.realStock,
                                      theoreticalStock: product.theoricalStock,
                                      historical: product.historical
                                    });
                                    handleBlur(
                                      stringSummableValues,
                                      replacedValue,
                                      obj,
                                      e,
                                      isLast
                                    );
                                  }}
                                  onFocus={() =>
                                    handleFocus(
                                      product,
                                      lastProductCounted,
                                      parametersInfos,
                                      countId,
                                      getId,
                                      userInfo
                                    )
                                  }
                                  onFocusProps={{
                                    product
                                  }}
                                  defaultValue={
                                    product?.labelAdditional ||
                                    product?.additional ||
                                    ''
                                  }
                                />
                              </FormControl>
                            </TdCount>

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes('OUTROS ESTOQUES') &&
                                'dNone'
                              }`}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('REAL') && 'dNone'
                              }`}
                            />

                            <td
                              width="2"
                              className={`description right p8 ${
                                !showColumns?.includes('TEÓRICO') && 'dNone'
                              }`}
                              style={{
                                borderRight: '1px double #BFC0CB!important'
                              }}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('ÚLTIMA') && 'dNone'
                              }`}
                            />

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes('CONSUMO') && 'dNone'
                              }`}
                            />

                            {/* <td width="5" className="description right p8"></td>  */}

                            <td
                              width="5"
                              className={`description right p8 borderLeftGray ${
                                !showColumns?.includes(
                                  'DIFERENÇA EM QTDE / R$'
                                ) && 'dNone'
                              }`}
                              style={{ borderLeft: 'solid 1px #BFC0CB' }}
                            />

                            <td
                              width="5"
                              className={`description right p8 ${
                                !showColumns?.includes('DIFERENÇA EM %') &&
                                'dNone'
                              }`}
                            />
                          </tr>
                        : null}

                        <tr>
                          <td colSpan={reportTabInfos?.active ? '10' : '13'}>
                            <hr style={{ backgroundColor: '#77798B' }} />
                          </td>
                        </tr>
                      </div>
                    );
                  })
                ) : (
                  <tr>
                    <th
                      colSpan={reportTabInfos?.active ? '10' : '13'}
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      Nenhum item encontrado
                    </th>
                  </tr>
                )}

                {(loading || loadingReportInfos) && (
                  <tr>
                    <th
                      colSpan={reportTabInfos?.active ? '10' : '13'}
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      <LinearProgress
                        style={{ height: '7px' }}
                        id="loading"
                        variant="query"
                      />
                    </th>
                  </tr>
                )}
              </tbody>
            </Table>

            <Tooltip
              title="*As qtdes informadas são salvas ao remover o foco do campo de
                cada linha"
            >
              <DivButtomExit className="fixedBackButtonContainer">
                <Button
                  onClick={() => {
                    localStorage.getItem('queue') || !navigator.onLine
                      ? setModalStates({
                          ...modalStates,
                          countNetworkDialog: true
                        })
                      : handleBack();
                  }}
                  className="defaultButton backButton fixedBackButton fontSizeDefault"
                  design="outlined"
                  label="Sair"
                />
              </DivButtomExit>
            </Tooltip>

            <GridFooter
              container
              className="actionButtons pt20 infoTableFooter"
              alignItems="center"
            >
              <Typography
                style={{
                  fontSize: '14px'
                }}
              >
                *As qtdes informadas são salvas ao remover o foco do campo de
                cada linha
              </Typography>
            </GridFooter>
          </div>
      </Container>

      {/* DIALOGS */}
      {modalStates.countDivergentCheckDialog && (
        <CountDivergentCheckDialog
          openModal={modalStates}
          handleClose={handleClose}
          backFunction={backFunction}
        />
      )}

      {modalStates.productDialog && (
        <DialogCount
          openState={modalStates}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          modalStates={modalStates}
          setModalStates={setModalStates}
          setConfirmModalInfos={setConfirmModalInfos}
          productList={productDialogList}
          onCloseProductDialog={() => {
            setModalStates({ ...modalStates, productDialog: false });
          }}
          productDialogTitle={productDialogTitle}
        />
      )}

      {modalStates.modalProduct && (
        <Suspense fallback={<span />}>
          <TheoryStockDialog
            getId={getId}
            openModal={modalStates}
            checkConsume={checkConsume}
            setCheckConsume={setCheckConsume}
            handleClose={handleClose}
            productExtractDescription={productDescription}
            productAge={null}
            productModal={productModal}
            setProductModal={setProductModal}
            parameters={parameters}
            originPage="countPage"
            defaultStartDate={
              new Date(
                moment(
                  modalSettings.find((modal) => modal.name === 'extracts').startDate
                )
              )
            }
            countDate={countDate || 
              modalSettings.find((modal) => modal.name === 'extracts').endDate
            }
            defaultExtractType={
              modalSettings.find((modal) => modal.name === 'extracts').extractType
            }
          />
        </Suspense>
      )}

      {modalStates.modalConfigPackage && (
        <Suspense fallback={<span />}>
          <ConfigPackageDialog
            getMainList={getProductList}
            openModal={modalStates}
            handleClose={handleClose}
            configPackageSkuItems={configPackageSkuItems}
            countDate={countDate}
            getId={getId}
            internalProducts={products}
            setInternalProduct={setProducts}
            setToastOpen={setToastOpen}
            setToastProps={setToastProps}
            setupId={setupId}
          />
        </Suspense>
      )}

      {modalStates.modalDiscard && (
        <Discard
          fullScreen={null}
          maxWidth="sm"
          loading={loadingDiscard}
          open={modalStates.modalDiscard}
          onSubmit={() => putDiscard()}
          onClose={() => {
            handleClose('modalDiscard');
          }}
          item={newData}
          setItem={setNewData}
          currentDiscardReason={currentDiscardReason}
          setCurrentDiscardReason={setCurrentDiscardReason}
          initialDate={countDate}
        />
      )}

      {currentCountDivergentDialogData && (
        <Suspense fallback={<span />}>
          <CountDivergentDialog
            openModal={modalStates}
            handleClose={handleClose}
            dialogData={currentCountDivergentDialogData}
            KeyCheck={KeyCheck}
            user={userInfo?.user}
            countId={countId}
          />
        </Suspense>
      )}

      {modalStates.countNetworkDialog && (
        <Suspense fallback={<span />}>
          <CountNetworkDialog
            openModal={modalStates}
            handleClose={handleCloseNetwork}
            backFunction={backFunction}
          />
        </Suspense>
      )}

      {modalStates?.confirmModal && (
        <ConfirmModal
          open={modalStates?.confirmModal}
          content={
            <Typography className="bold">
              {confirmModalInfos?.contentText}
            </Typography>
          }
          onClose={() => {
            setModalStates({
              ...modalStates,
              confirmModal: false
            });
          }}
          cancelText="Não"
          onConfirm={onConfirmConfirmationDialogButton}
          confirmText="Sim"
        />
      )}

      {modalStates.confirmCount && (
        <ConfirmModal
          open={modalStates.confirmCount}
          content={
            <Typography className="bold">
              Atenção! Qtde informada possui uma variação alta em relação ao
              estoque teórico.
            </Typography>
          }
          onClose={() => {
            setConfirmModalAction('cancel');
            setUpdateValueByModal(Math.random())
            handleCancelConfirmCount();

            setTimeout(() => {
              currentInput?.ref?.current?.focus()
              setCurrentInput({
                input: '',
                isLast: '',
                ref: ''
              })
            }, 200);
          }}
          cancelText="Cancelar"
          onConfirm={() => {
            setConfirmModalAction('confirm');
            setUpdateValueByModal(Math.random())
            
            handleBlur(
              modalConfirmCountObj.stringSomableValues,
              modalConfirmCountObj.replacedValue,
              modalConfirmCountObj.handleBlurCountObj,
              null,
              null,
              true
            );

            handleFocus(
              modalConfirmCountObj?.product,
              lastProductCounted,
              parametersInfos,
              countId,
              getId,
              userInfo
            );

            setTimeout(() => {
              focusOnNextField(currentInput?.input, currentInput?.isLast, 300);
            }, 300);
          }}
          disabledButton={disabledConfirmCount}
          confirmText="Confirmar"
        />
      )}

      {modalStates.discardPercentageAndValue && (
        <Suspense fallback={<span>Carregando...</span>}>
          <DiscardPercentageAndValue
            open={modalStates.discardPercentageAndValue}
            handleClose={() => {
              setModalStates({
                ...modalStates,
                discardPercentageAndValue: false
              });
            }}
            setProductModal={setProductModal}
            initialDate={moment(date)
              .subtract(periodSubtractDays, 'day')
              .format('YYYY-MM-DD')}
            countDate={countDate}
            currentRestaurant={userInfo.companiesActive.name}
            infoDiscard={infoDiscard}
            setInfoDiscard={setInfoDiscard}
            setModalStates={setModalStates}
            modalStates={modalStates}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            countId={countId}
            countGroupInfos={countGroupInfos}
          />
        </Suspense>
      )}

      <Snackbar
        open={modalStates.hasCatalogChanges}
        className="hasCatalogChangesDialog"
      >
        <Alert 
          onClose={() => {
            setModalStates({
              ...modalStates,
              hasCatalogChanges: false
            });
          }}
          icon={false}
          severity='warning'
        >
          {countGroupInfos?.countGroup?.counts
            ?.map((count) => count?.id === parseFloat(countId) && count)
            .flat()
            .filter((item) => !!item)[0]?.hasChange === 'true' ? (
            <p className="mb0" style={{ fontWeight: 'normal' }}>
              * Contagem <b>COM</b> alterações de Catálogo,
              <span
                style={{ color: '#5062F0', margin: '0 3px' }}
                onClick={() =>
                  handleProductDialog(
                    periodicity?.description,
                    storageLocationDescription
                  )
                }
                className="cursorPointer underline"
              >
                clique
              </span>
              para visualizá-las
            </p>
          ) : null}
        </Alert>
      </Snackbar>

      <Snackbar
        open={toastOpen}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={toastProps?.severity}>
          {toastProps?.message}
        </Alert>
      </Snackbar>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => handleClosePopover()}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px' }
        }}
        className="columnConfigPopover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="dFlex alignStart justifyStart flexColumn">
          {renderShowColumnItem('OUTROS ESTOQUES')}
          {renderShowColumnItem('REAL')}
          {renderShowColumnItem('TEÓRICO')}
          {renderShowColumnItem('ÚLTIMA')}
          {renderShowColumnItem('CONSUMO')}
          {renderShowColumnItem('DIFERENÇA EM QTDE / R$')}
          {renderShowColumnItem('DIFERENÇA EM %')}
          {renderShowColumnItem('USUÁRIO')}
        </div>
      </Popover>

      <SimpleModal
        closeFunction={() => {
          dispatch(close());
        }}
      />

      <StockDialog 
        modalSettings={modalSettings}
        setModalSettings={setModalSettings}
        stocks={realStockModal}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  countInfo: state.count,
  assortmentInfo: state.assortment
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...AssortmentActions, ...UserActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiaryCount);
