/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Popover,
  Snackbar,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { useSelector , connect, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import UpdateDiscard from 'components/Dialogs/Discard/UpdateDiscard';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { handleExtractDialogColumns } from 'store/reducers/userConfigs';
import Environment from 'utils/environments';

import DrillDownDialog from './DrillDownGroup';
import TheoryStockBody from './TheoryStockBody';
import TheoryStockHead from './TheoryStockHead';
import TheoryStockMenu from './TheoryStockMenu';

const environments = Environment(process.env.REACT_APP_ENV);

function TheoryStockDialog({
  productExtractDescription,
  productAge,
  modalProduct,
  modalStates,
  checkConsume,
  defaultStartDate,
  extractEndDate,
  extractType,
  discardId,
  productModal,
  parameters,
  originPage,
  setProductModal,
  getId,
  handleClose,
  setExtractEndDate,
  setExtractType,
  fetchCards,
  extractDateAndDescription,
  handleGetDiffs,
  differenceInExtractTooltip,
  setDifferenceInExtractTooltip,
  autocompleteDescription,
  setAutocompleteDescription,
  weekDays,
  setRangeDateItems,
  setStartDate,
  setEndDate,
  setSelectedItem,
  setAnalytic
}) {
  const user = useSelector((currState) => currState.user);
  const dispatch = useDispatch();
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [filterSelectedProduct, setFilterSelectedProduct] = useState();
  const [drillDownInfo, setDrillDownInfo] = useState({
    info: '',
    productsIds: ''
  })
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const storedUserExtractDialogConfig = localStorage.getItem('extractDialogConfigs')?.length
    ? localStorage.getItem('extractDialogConfigs')?.split(',')
    : [];
  const [showColumns, setShowColumns] = useState(storedUserExtractDialogConfig || []);

  const isOriginPageWithLastCount =
    originPage === 'productPage' ||
    originPage === 'stocksPage' ||
    originPage === 'countPage';

  const initialStartDate = 
    isOriginPageWithLastCount
      ? productModal?.lastCount
        ? Math.abs(moment(productModal?.lastCount).diff(new Date(), 'days')) > 30 
          ? moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD') 
          : moment(productModal.lastCount).format('YYYY-MM-DD')
        : defaultStartDate || moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD') 
      : defaultStartDate || new Date();

  const [cardsRelatedInitialDate, setCardsRelatedInitialDate] = useState(moment(initialStartDate).add(1, 'day'));
  const [extractStartDate, setExtractStartDate] = useState(weekDays ? moment(extractEndDate).subtract(7, 'days') : initialStartDate);
  const [extractItems, setExtractItems] = useState([]);
  const [analyticFilter, setAnalyticFilter] = useState(true);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  useEffect(() => {
    setAnalytic(analyticFilter)
  }, [analyticFilter])
  
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'updateDiscard',
      open: false,
      fullScreen: null,
      maxWidth: 'sm',
      discard: '',
      product: '',
      date: ''
    },
    {
      id: 2,
      name: 'confirmDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
    {
      id: 3,
      name: 'drillDownDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    }
  ]);

  const [filtersExtractModal, setFiltersExtractModal] = useState(
    () => [
      {
        param: 'type',
        value: extractType || '',
        urlParam: `type=${extractType || ''}`
      },
      {
        param: 'date',
        value: moment(extractStartDate).format('YYYY-MM-DD'),
        urlParam: `date=${moment(extractStartDate).format('YYYY-MM-DD')}`
      },
      {
        param: 'dateEnd',
        value: moment(extractEndDate).format('YYYY-MM-DD'),
        urlParam: `dateEnd=${moment(extractEndDate).format('YYYY-MM-DD')}`
      },
      {
        param: 'discardId',
        value: discardId || '',
        urlParam: `discardId=${discardId || ''}`
      }
    ],
    [extractEndDate, extractStartDate]
  );

  const getExtracts = useCallback(
    (product) => {
      (async () => {
        setIsLoading(true);
        const params = filtersExtractModal
          .map((filter) => filter.urlParam)
          .join('&');

        const originCardIdParam = product?.originIdCard ? `&cardId=${product?.originIdCard}` : ''

        const response = await fetch(
          `${environments.catalogV3}/extracts?originId=${getId}&productId=${product?.id}&allExtracts=true${originCardIdParam}&${params}`
        );
        const responseJson = await response.json();

        if (responseJson?.content?.length) {
          const extracts = responseJson?.content
          const formattedObj = extracts?.map((el, elIndex) => {
            const isLastEl = elIndex === extracts?.length - 1;

            if (elIndex === 0 || isLastEl) {
              return {
                ...el,
                extracts: el?.extracts?.map((extract, extractIndex) => {
                  const isTheoreticalOrEndExtract = extract?.type === "THEORICAL" || extract?.type === "END"

                  if ((extractIndex === 0 && !isLastEl) || (isLastEl && isTheoreticalOrEndExtract)) {
                    return {
                      ...extract,
                      hideItem: false
                    }
                  }

                  return { 
                    ...extract,
                    hideItem: true 
                  }
                })
              }
            }

            return {
              ...el,
              extracts: el?.extracts?.map((extract) => ({ 
                ...extract,
                hideItem: true 
              }))
            }
          })

          setStartDate(extractStartDate)
          setEndDate(extractEndDate)
          setRangeDateItems(formattedObj)
          setExtractItems(formattedObj);
        } else {
          setExtractItems([]);
          setIsLoading(false);
        }
        setIsLoading(false);
      })();
    },
    [filtersExtractModal, getId]
  );

  const updateDiscardFetch = (body, isDelete) =>{
    if (isDelete) {
      axios
        .delete(
          `${environments.catalogV2}/extracts/${body.extractId}?originId=${getId}`,
          { data: body }
        )
        .then(() => {
          setMessage({
            description: 'Descarte excluido com sucesso.',
            status: 'success'
          });

          setModalSettings((modal) =>
            modal.map((item) =>
              item.name === 'updateDiscard'
                ? {
                    ...item,
                    open: false
                  }
                : item
            )
          );

          getExtracts(productModal);
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            description: 'Erro ao excluir descarte.',
            status: 'error'
          });
        });
    } else {
      axios
        .put(
          `${environments.catalogV2}/extracts/${body.extractId}?originId=${getId}`,
          body
        )
        .then(() => {
          setMessage({
            description: 'Descarte atualizado com sucesso.',
            status: 'success'
          });

          setModalSettings((modal) =>
            modal.map((item) =>
              item.name === 'updateDiscard'
                ? {
                    ...item,
                    open: false
                  }
                : item
            )
          );

          getExtracts(productModal);
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            description: 'Erro ao atualizar descarte.',
            status: 'error'
          });
        });
    }
  }

  const handleUpdateDiscard = (obj, isDelete = false) => {
    if(Array.isArray(obj)){
      obj.map(itemObj => {
        const body = {
          extractId: itemObj?.staticID || itemObj?.extract?.id || itemObj?.discard?.id,
          discardId: itemObj?.discard?.id,
          productId: itemObj?.product?.id,
          quantity: itemObj?.quantity,
          originId: getId,
          user: user?.user
        };

        updateDiscardFetch(body, isDelete);
      })
    } else {
      const body = {
        extractId: obj?.staticID || obj?.extract?.id || obj?.discard?.id,
        discardId: obj?.discard?.id,
        productId: obj?.product?.id,
        quantity: obj?.quantity,
        originId: getId,
        user: user?.user
      };

      updateDiscardFetch(body, isDelete);
    }
  };

  const handleAutocompleteChange = useCallback(
    (_, value) => {
      const currentSelectedProduct = autocompleteOptions.find(
        (option) => option?.id === value?.id
      );

      if (value){
        getExtracts(currentSelectedProduct);
        setFilterSelectedProduct(value);
        setProductModal(value);
      } else {
        setExtractItems([]);
      }
    },
    [autocompleteOptions, getExtracts]
  );

  const handleSaveExtractDialogConfig = (columnsFormatted) => {
    const body = {
      configs: [
        {
          extractsDialog: {
            showColumns: columnsFormatted
          }
        }
      ],
      user: user?.user
    };

    axios
      .put(`${environments.auth}/users/${user?.user}/config`, body)
      .then(() => {
        setShowColumns(columnsFormatted);
        dispatch(handleExtractDialogColumns({ configs: body?.configs }));
      })
      .catch((error) => {
        console.log('error', error);
        setMessage({
          message: 'Erro ao atualizar configurações',
          severity: 'error'
        });
      });
  };

  const renderShowColumnItem = (column, label) => {
    const handleChangeColumnsView = (checked) => {
      const columnsFormatted = checked
        ? [...showColumns, column]
        : showColumns?.filter((el) => el !== column);

        handleSaveExtractDialogConfig(columnsFormatted);
    };

    const showCurrentColumn = showColumns?.includes(column);

    return (
      <div className="dFlex alignCenter">
        <Checkbox
          style={{
            marginTop: 4,
            marginLeft: 0,
            color: '#FFF'
          }}
          name="View Mode"
          checked={showCurrentColumn}
          className="checkBoxSmall"
          onChange={(_, checked) => {
            handleChangeColumnsView(checked);
          }}
          size="small"
        />

        <label
          onClick={() => handleChangeColumnsView(!showCurrentColumn)}
          className="initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer 
           mb0
          "
          style={{
            lineHeight: 'normal',
            color: '#0F1225'
          }}
        >
          {label || column}
        </label>
      </div>
    );
  };

  useEffect(() => {
    if (modalProduct) {
      getExtracts(productModal);
    }
  }, [filtersExtractModal, modalProduct, productModal]);

  useEffect(() => {
    setExtractItems((prevState) =>
      prevState.map((el) => ({
        ...el,
        opened: analyticFilter
      }))
    );
  }, [analyticFilter]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={modalProduct}
        onClose={() => handleClose('modalProduct')}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal extractModal"
        style={{
          zIndex:
            modalStates.modalCards || modalStates.modalTechnicalSheet
              ? 1299
              : 1300
        }}
        transitionDuration={0}
      >
        <DialogTitle
          className={`modalHeader bold textAlignCenter cursorMove ${
            !extractStartDate && 'navyBlue'
          }`}
          id="draggable-dialog-title"
        >
          <Typography>DETALHAMENTO DE ESTOQUE</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose('modalProduct')}
          >
            <img
              style={{ width: '16px', height: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className={`modalContent ${extractStartDate ? 'p16' : 'p0 pb10'}`}
          style={{ background: extractStartDate ? '#F6F6F8' : '#FFF' }}
        >
          <TheoryStockMenu
            extractStartDate={extractStartDate}
            extractEndDate={extractEndDate}
            extractType={extractType}
            filtersExtractModal={filtersExtractModal}
            productModal={productModal}
            setFiltersExtractModal={setFiltersExtractModal}
            setExtractStartDate={setExtractStartDate}
            setCardsRelatedInitialDate={setCardsRelatedInitialDate}
            setExtractEndDate={setExtractEndDate}
            setExtractType={setExtractType}
            getExtracts={getExtracts}
            analyticFilter={analyticFilter}
            setAnalyticFilter={setAnalyticFilter}
            productExtractDescription={productExtractDescription}
            productAge={productAge}
            autocompleteOptions={autocompleteOptions}
            setAutocompleteOptions={setAutocompleteOptions}
            user={user}
            getId={getId}
            handleAutocompleteChange={handleAutocompleteChange}
            filterSelectedProduct={filterSelectedProduct}
            autocompleteDescription={autocompleteDescription}
            setAutocompleteDescription={setAutocompleteDescription}
          />

          <div
            className={`dFlex flexColumn pt0 ${
              extractStartDate && 'cardTable'
            }`}
          >
            <table className="realStock noBoxStyle">
              <TheoryStockHead 
                extractItems={extractItems} 
                showColumns={showColumns} 
                setAnchorEl={setAnchorEl}
              />

              {isLoading ? (
                <td colSpan="7">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </td>
              ) : (
                <TheoryStockBody
                  analyticFilter={analyticFilter}
                  checkConsume={checkConsume}
                  setAnalyticFilter={setAnalyticFilter}
                  setExtractItems={setExtractItems}
                  extractItems={extractItems}
                  fetchCards={fetchCards}
                  modalStates={modalStates}
                  productModal={productModal}
                  extractDateAndDescription={extractDateAndDescription}
                  extractType={extractType}
                  parameters={parameters}
                  handleGetDiffs={handleGetDiffs}
                  differenceInExtractTooltip={differenceInExtractTooltip}
                  setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
                  getId={getId}
                  extractStartDate={cardsRelatedInitialDate || extractStartDate}
                  extractEndDate={extractEndDate}
                  modalSettings={modalSettings}
                  setModalSettings={setModalSettings}
                  setDrillDownInfo={setDrillDownInfo}
                  drillDownInfo={drillDownInfo}
                  setSelectedItem={setSelectedItem}
                  showColumns={showColumns}
                />
              )}
            </table>
          </div>
        </DialogContent>
      </Dialog>

      {modalSettings.find((modal) => modal.name === 'updateDiscard').open && (
        <UpdateDiscard
          fullScreen={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .fullScreen
          }
          extractItems={extractItems?.map(el => el?.extracts)?.flat()}
          maxWidth={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .maxWidth
          }
          open={
            modalSettings.find((modal) => modal.name === 'updateDiscard').open
          }
          onSubmit={handleUpdateDiscard}
          product={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .product
          }
          discard={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .discard
          }
          date={
            modalSettings.find((modal) => modal.name === 'updateDiscard').date
          }
          quantity={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .quantity
          }
          unity={
            modalSettings.find((modal) => modal.name === 'updateDiscard').unity
          }
          extract={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .extract
          }
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'updateDiscard'
                  ? {
                      ...item,
                      open: false
                    }
                  : item
              )
            );
          }}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'confirmDialog')?.open && (
        <ConfirmModal
          open={
            modalSettings.find((modal) => modal.name === 'confirmDialog').open
          }
          title={
            modalSettings.find((modal) => modal.name === 'confirmDialog')?.title
          }
          content={
            <Typography className="bold">
              Deseja realmente excluir esse descarte?
            </Typography>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          cancelText="Não"
          onConfirm={() => {
            const bodyUpdated = {
              ...modalSettings.find((modal) => modal.name === 'confirmDialog')
                ?.body,
              quantity: 0
            };

            handleUpdateDiscard(bodyUpdated, true);

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          confirmText="Sim"
        />
      )}

      {modalSettings.find((modal) => modal.name === 'drillDownDialog')?.open && (
        <DrillDownDialog
          openModal={modalSettings}
          handleClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'drillDownDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          info={drillDownInfo}
          productModal={productModal}
          getId={getId}
        />
      )}

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px' }
        }}
        className="columnConfigPopover extractDialogColumnsConfigPopover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="dFlex alignStart justifyStart flexColumn">
          {renderShowColumnItem('CUSTO')}
          {renderShowColumnItem('VALOR UNIT', 'VALOR')}
          {renderShowColumnItem('QTDE FINAL')}
          {renderShowColumnItem('VALOR FINAL')}
        </div>
      </Popover>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => setMessage({ description: '', status: '' })}
      >
        <Alert
          onClose={() => setMessage({ description: '', status: '' })}
          severity={message.status}
          color={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TheoryStockDialog);