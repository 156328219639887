import Axios from "axios"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { Collapse, Dialog, DialogContent, DialogTitle, ListSubheader, Typography, MenuItem, useMediaQuery } from "@material-ui/core"
import { ExpandMore, ExpandLess } from "@material-ui/icons"
import Environment from "utils/environments"
import history from "utils/history"
import * as UserActions from "store/actions/user"
import { handleCountColumns, handleMenusColumns } from "store/reducers/userConfigs"
import Password from "components/Dialogs/ChangeUser/password"
import CommonAlert from "components/CommonAlert/CommonAlert"

function Index({
    open,
    close,
    userInfo,
    setProfileName,
    companiesSet
}) {

    const setOperationalUsers = () => {
        const groupped = []
        const array = userInfo?.operationalUsers || []

        if (userInfo?.userMaster && Array.isArray(userInfo?.userMaster.operationalUsers)) {
            array.length = 0

            if (userInfo?.userMaster.operationalUsers) {
                array.push(...userInfo?.userMaster?.operationalUsers)
            }
        }

        for (const user of array) {
            const profileName = setProfileName([user.profile], false)

            const idx = groupped.findIndex(g => g.profile.original === profileName)

            const userBody = {
                username: user._id,
                firstName: user.firstName,
                lastName: user.lastName,
                password: user.password
            }

            if (idx === -1) {
                groupped.push(
                    {
                        profile: {
                            original: profileName,
                            translated: setProfileName([user.profile])
                        },
                        users: [userBody]
                    }
                )
            } else {
                groupped[idx].users.push(userBody)
            }
        }

        return groupped.sort((a, b) => a.profile.translated.localeCompare(b.profile.translated))
    }

    const location = useLocation()
    const dispatch = useDispatch()
    const environments = Environment(process.env.REACT_APP_ENV)

    const username = userInfo && userInfo?.userMaster?._id ? userInfo?.userMaster?._id : userInfo && userInfo?.user ? userInfo?.user : ""
    const profileName = userInfo && userInfo?.userMaster?.profile ? setProfileName([userInfo?.userMaster?.profile]) : userInfo && userInfo?.profile ? setProfileName(userInfo?.profile) : ""
    const operationalUsers = userInfo && userInfo?.operationalUsers ? setOperationalUsers() : []

    const isMobile = useMediaQuery('(max-width: 821px)')
    const onDismiss = () => setAlertErr(false)
    const [alertErr, setAlertErr] = useState(false)
    const [collapsed, setCollapsed] = useState(operationalUsers)
    const [messageError, setMessageError] = useState("")
    const [openPassword, setOpenPassword] = useState(false)

    const handleClickOpenPassword = () => {
        if (userInfo?.user != username) {
            setOpenPassword(true)
        } else {
            close()
        }
    }

    const handleClosePassword = () => {
        setOpenPassword(false)
    }

    const handleMenuItemClick = (user, profile, password = undefined) => {
        if (!user && !profile) {
            return false
        }

        const isManagers = ["restaurants", "managers"].includes(profile)

        const body = {
            credential: user._id ? user._id : user.username ? user.username : null,
            password: isManagers ? password : user.password,
            fromUsername: userInfo?.userMaster?._id ? userInfo?.userMaster?._id : userInfo?.user
        }

        Axios.post(`${environments.auth}/users/auth`, body)
            .then((response) => {
                const { data = undefined } = response

                const expireCountdownInSeconds = data?.expireCountdownOpUser ? data?.expireCountdownOpUser : 300

                if (data) {
                    companiesSet(
                        data.user,
                        data.companies,
                        data.profile,
                        data.keycloakId,
                        data.systemType,
                        data.configs,
                        data.operationalUsers,
                        data.userMaster
                    )

                    dispatch(handleCountColumns({ configs: data?.configs }))
                    dispatch(handleMenusColumns({ configs: data?.configs }))

                    if (isManagers) {
                        localStorage.removeItem("changeUserExpireCountdown")
                    } else {
                        localStorage.setItem("changeUserExpireCountdown", Number(expireCountdownInSeconds))
                    }

                    localStorage.setItem("token", JSON.stringify(response))

                    // (11/09) Vamos buscar apenas por usuários do tipo "produção (producers)", "cozinha (manufactures)" e "estoquista (stockists)"
                    // history.push(`/${data.companies.groups[0].subGroups[0].stores[0].id}`, location.state + [response.data])
                }

                handleClosePassword()
                close()
            })
            .catch((error) => {
                console.log(error)
                setMessageError(error?.response?.data?.message)
                setAlertErr(true)
            })
    }

    return (
        <Dialog
            className="defaultModalChangeUser selectUserDialog"
            open={open}
            style={{
                backgroundColor: "rgb(69,74,103,0.80)",
                width: "100%"
            }}
        >
            <DialogTitle
                className={isMobile ? "modalHeaderChangeUserMobile navyBlue" : "modalHeader navyBlue"}
            >
                <Typography
                    style={{ textTransform: "inherit" }}
                >
                    {isMobile ? "SELECIONE O SEU USUÁRIO:" : "Selecione o usuário:"}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <ListSubheader
                    className={isMobile ? "subGroupHeaderMobile" : "subGroupHeader"}
                    // className={username == userInfo?.user ? "subGroupHeaderActive" : "subGroupHeader"}
                    onClick={() => handleClickOpenPassword()}
                >
                    {`${profileName}: ${username}`}
                </ListSubheader>

                {
                    operationalUsers.map((group, index) => (
                        <>
                            <ListSubheader
                                key={index}
                                className="subSelectHeader"
                                onClick={() => {
                                    setCollapsed(
                                        collapsed.map((item) => item.profile.original === group.profile.original ? { ...item, open: !item.open } : item)
                                    )
                                }}
                            >
                                {isMobile ? String(group.profile.translated).toUpperCase() : group.profile.translated}
                                {!collapsed?.find((item) => item.profile.original === group.profile.original)?.open ? (
                                    <ExpandLess
                                        className="cursorPointer"
                                        style={{ color: "#F2F3F8" }}
                                    />
                                ) : (
                                    <ExpandMore
                                        className="cursorPointer"
                                        style={{ color: "#F2F3F8" }}
                                    />
                                )}
                            </ListSubheader>

                            <Collapse
                                in={!collapsed.find((item) => item.profile.original === group.profile.original)?.open || false}
                                timeout={0}
                            >
                                {
                                    group.users.sort((a, b) => a.username.localeCompare(b.username)).map((user, userIndex) => (
                                        <>
                                            <MenuItem
                                                className={`${isMobile ? "menuItemMobile" : "menuItem"} storeHeader`}
                                                key={userIndex}
                                                // disabled={user.username == userInfo?.user}
                                                onClick={() => handleMenuItemClick(user, group.profile.original)}
                                            >
                                                {user.username}
                                            </MenuItem>
                                            <hr
                                                style={{margin: "0px", backgroundColor: "#D2D3D7"}}
                                            />
                                        </>
                                    ))
                                }
                            </Collapse>
                        </>
                    ))
                }
            </DialogContent>

            <Password
                open={openPassword}
                close={handleClosePassword}
                userInfo={userInfo}
                setProfileName={setProfileName}
                handleMenuItemClick={handleMenuItemClick}
            />

            <CommonAlert
                open={alertErr}
                onClose={onDismiss}
                indexMessage={messageError}
            />
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.user
})

const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Index)