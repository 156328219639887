/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  Dialog,
  TextField,
  Typography,
  Paper,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import FullScreenLoading from 'components/FullScreenLoading/index';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-storage"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function StorageLocationForm({
  userInfo,
  storageLocationInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  selectedItems,
  setSelectedItems,
  jsonBody,
  setJsonBody,
  openModal,
  updateList
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const onDismiss = () => setAlertErr(false);
  const [editMode, setEditMode] = useState(
    storageLocationInfo?.action?.toLowerCase() === 'edit' || false
  );
  const [producersId, setProducersId] = useState(
    editMode ? storageLocationInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? storageLocationInfo.data.description : ''
  );
  const [activeSwitch, setActiveSwitch] = useState(true);

  const convertStatus = (value) => {
    if (value === 'ACTIVE') return true;
    if (value === 'INACTIVE') return false;
    if (value === true) return 'ACTIVE';
    if (value === false) return 'INACTIVE';
  };

  useEffect(() => {
    setActiveSwitch(convertStatus(storageLocationInfo?.data?.status));
  }, []);

  const onSubmitStorageLocation = () => {
    setLoading(true);

    const config = {
      headers: {
        User: `${userLocal?.user}`
      }
    };

    if (editMode) {
      axios
        .put(
          `${environments.catalog}/storageLocations/${producersId}`,
          {
            method: 'PUT',
            description,
            status: convertStatus(activeSwitch),
            restaurantId: userInfo.companiesActive.id
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose(true);
            }, 1000);
          // const { data } = res
          setAlertMessage('Cadastro realizado com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(
          `${environments.catalog}/storageLocations`,
          {
            method: 'POST',
            description,
            restaurantId: userInfo.companiesActive.id,
            user: userInfo.user
          },
          config
        )
        .then((response) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);

          if (isModal && setSelectedItems && setJsonBody) {
            setSelectedItems({
              ...selectedItems,
              product: {
                ...selectedItems?.product,
                storage: [
                  {
                    id: response?.data?.id,
                    description: response?.data?.description
                  }
                ]
              }
            });

            setJsonBody({
              ...jsonBody,
              product: {
                ...jsonBody?.product,
                storage: [
                  {
                    id: response?.data?.id,
                    description: response?.data?.description
                  }
                ]
              }
            });
          }

          onClose &&
            setTimeout(() => {
              onClose(true);
            }, 1000);
          // const { data } = res
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Dialog
      className="defaultModal storageRegisterDialog"
      style={{ width: '50%' }}
      open={openModal}
      // fullWidth={true}
      maxWidth="xl"
      onClose={() => {
        onClose()
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-storage"
      transitionDuration={0}
    >
      <Container className="w100" style={{ marginBottom: '0px' }}>
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          style={{
            borderRadius: '4px 4px 0 0'
          }}
          id="draggable-dialog-title-storage"
        >
          <Typography>CADASTRO DE LOCAL DE ESTOQUE</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        {alertErr ? (
          <CommonAlert
            open={alertErr}
            onClose={onDismiss}
            indexMessage={alertMessage}
            statusCode={statusCode}
            width="100%"
          />
        ) : null}

        <FullScreenLoading
          widthPage={100}
          leftPage={-160}
          display={loading}
          description="Salvando"
        />
        {/* {loading ? <LinearProgress /> : null} */}

        <div
          className="cardDefault pt16"
          style={{ borderRadius: '0', width: 'auto', minWidth: '550px' }}
        >
          <form onSubmit={handleSubmit(onSubmitStorageLocation)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={12} className="pl0 pr0">
                <TextField
                  label="Nome"
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  variant="outlined"
                  size="small"
                  value={description}
                  defaultValue={
                    editMode ? storageLocationInfo.data.description : null
                  }
                  fullWidth
                  required="required"
                  inputProps={{ maxLength: 50 }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {editMode && (
                <Grid>
                  <FormControlLabel
                    className="toggleDefault"
                    control={
                      <Switch
                        onChange={() => {
                          setActiveSwitch(!activeSwitch);
                        }}
                        name="draft"
                        checked={activeSwitch}
                      />
                    }
                    label="Ativo"
                  />
                </Grid>
              )}
            </Grid>

            <CommonAlert
              open={open}
              onClose={handleClose}
              messagePersonal="Cadastro realizado com sucesso!"
              severity="success"
            />

            <Grid
              item
              xs={12}
              sm={12}
              className="p0 dFlex justifyEnd bgWhite"
              style={{
                borderRadius: '0 0 4px 4px'
              }}
            >
              {!isModal && (
                <Link to="/inventario/locais-de-armazenamento/">
                  <Button
                    className="defaultButton backButton"
                    design="outlined"
                    label="Voltar"
                    style={{ marginRight: '20px' }}
                  />
                </Link>
              )}

              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Salvar"
                design="contained"
                onSubmit={handleSubmit(onSubmitStorageLocation)}
              />
            </Grid>
          </form>
        </div>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  storageLocationInfo: state.storageLocations
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageLocationForm);
